import React from 'react';
import { CreateFeedback } from './CreateFeedback';
import { Dialog } from '@koopajs/mui';
import { RecordVote } from './RecordVote';
import { AddResolution } from './AddResolution';
import { SendMeeting } from './SendMeeting';
import { AddLinkDialog } from './AddLinkDialog';
import { SendResolution } from './SendResolution';
import { AddDocumentRecordDialog } from './AddDocumentRecordDialog';
import { AddDocumentToDocumentRecordDialog } from './AddDocumentToDocumentRecordDialog';
import { AddDocumentToHistoryDialog } from './AddDocumentToHistoryDialog';
import { BoardMemberViewDocumentDialog } from './BoardMemberViewDocumentDialog';
import { ViewDocumentPdf } from './ViewDocumentPdf';

export const Dialogs: React.FC = () => (
  <>
    <CreateFeedback />
    <Dialog.InviteUser
      options={[
        // { id: 'admin', key: 'admin' },
        { id: 'member', key: 'member' }
      ]}
      i18n={{ keyPrefix: 'Dialogs.InviteUser' }}
    />
    {/* <Dialog.SelectPlan /> */}
    {/* // TODO: Fix default koopa translations */}
    <Dialog.CreateWorkspace i18n={{ keyPrefix: 'koopaCore:DialogCreateWorkspace' }} />
    <RecordVote />
    <AddResolution />
    <SendMeeting />
    <AddLinkDialog />
    <SendResolution />
    <AddDocumentRecordDialog />
    <AddDocumentToDocumentRecordDialog />
    <AddDocumentToHistoryDialog />
    <BoardMemberViewDocumentDialog />
    <ViewDocumentPdf />
  </>
);
