import React, { useCallback, useRef, useEffect } from 'react';
import { Dialog, Loading } from '@koopajs/mui';
import { useHistory } from 'react-router-dom';
import { useResourceShow } from '@koopajs/react';
import { IMeeting } from 'types';
import { Box, Divider, Paper, Typography, Stack } from '@mui/material';
import { RenderPrintButton } from 'components/RenderPrintButton';
import { EventsTimeline } from 'components/EventsTimeline';
import { generateMeetingTimeline } from 'utils/generateMeetingTimeline';
import { useLocale } from '@koopajs/react';
import { calculateMeetingDuration } from 'utils/calculateMeetingDuration';
import { TimerOutlined as TimerOutlinedIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { MeetingViewTopicCard } from 'components/Meetings/View/MeetingViewTopicCard';
import { MeetingViewHeaderCard } from 'components/Meetings/View/MeetingViewHeaderCard';
import { MeetingPrintVersion } from 'components/Meetings/MeetingPrintVersion';
import { calculateTopicsStartTime } from 'utils/calculateTopicsStartTime';

interface IViewPastMeeting {
  pastMeetingId?: string;
  pathOnClose: string;
  routerStateOnClose?: { activePath: string };
}

export const ViewPastMeeting: React.FC<IViewPastMeeting> = (props) => {
  const { pastMeetingId, pathOnClose, routerStateOnClose } = props;

  const history = useHistory();

  const {
    resource: pastMeeting,
    isProcessing,
    errorMessage
  } = useResourceShow<IMeeting>({
    path: '/meetings',
    id: pastMeetingId
  });

  const { t } = useLocale();
  const keyPrefix = 'Dialogs.PastMeetingDialog';
  const extractedErrorMessage = extractErrorMessage(errorMessage);

  const handleCloseDialog = useCallback(() => {
    history.push({ pathname: pathOnClose, search: history.location?.search }, routerStateOnClose);
  }, [history, pathOnClose]);

  const componentRef = useRef(null);

  const timelineEvents = generateMeetingTimeline(pastMeeting);

  const meetingDuration = calculateMeetingDuration(t, pastMeeting?.topics);

  const topics = pastMeeting && calculateTopicsStartTime(pastMeeting);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (errorMessage === "Error 403: You don't have access to this meeting") {
      enqueueSnackbar(extractedErrorMessage, {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
      history.push('/past-meetings');
    }
  }, [errorMessage, enqueueSnackbar, closeSnackbar, pastMeetingId]);

  return (
    <Dialog.View
      isOpen={Boolean(pastMeetingId && pastMeeting)}
      onClose={handleCloseDialog}
      isCloseVisible={true}
      i18n={{ keyPrefix: keyPrefix + '.DialogView' }}
      footerActions={
        <RenderPrintButton
          keyPrefix={keyPrefix + '.DialogView'}
          componentRef={componentRef}
          documentTitle={pastMeeting?.title}
          size="small"
        />
      }
      dialogProps={{ className: 'rr-block', PaperProps: { 'data-cy': 'view-past-meeting-dialog' } }}
    >
      <Box sx={{ display: 'none' }}>
        <MeetingPrintVersion
          meeting={pastMeeting && { ...pastMeeting, topics }}
          showApprovedPastMeetingsToApprove
          ref={componentRef}
        />
      </Box>

      {isProcessing ? (
        <Box sx={{ my: 3 }}>
          <Loading sx={{ backgroundColor: 'transparent' }} />
        </Box>
      ) : (
        pastMeeting && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 5, my: 3 }}>
              <Paper
                elevation={6}
                sx={{
                  mb: 5,
                  p: { xs: '18px', lg: '60px' },
                  maxWidth: '912px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '14px',
                  minWidth: 0
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <MeetingViewHeaderCard meeting={pastMeeting} />

                  <Divider />

                  <Typography variant="overline">{t(keyPrefix + '.agendaLabel')}</Typography>

                  {topics
                    ?.filter((t) => !t.deletedAt)
                    .map((topic, index, array) => {
                      return (
                        <>
                          <MeetingViewTopicCard
                            topic={topic}
                            showApprovedPastMeetingsToApprove
                            order={index + 1}
                          />
                        </>
                      );
                    })}

                  <Divider />

                  <Stack alignItems="flex-end">
                    <Typography
                      variant="body1"
                      sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' } }}
                    >
                      <TimerOutlinedIcon color="action" sx={{ mr: 1 }} />
                      {t(keyPrefix + '.estimatedDurationLabel', { time: meetingDuration })}
                    </Typography>
                  </Stack>
                </Box>
              </Paper>
            </Box>

            <EventsTimeline timelineEvents={timelineEvents} />
          </>
        )
      )}
    </Dialog.View>
  );
};
