import { IMeeting } from 'types';
import { useLocale, useWorkspaceShow } from '@koopajs/react';
import { Box, Stack, Paper, Divider, Typography, Link } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import React, { forwardRef } from 'react';
import { TopicCardMeetingPrint } from 'components/TopicCard/TopicCardMeetingPrint';
import { ParticipantsList } from 'components/ParticipantsList';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { DateTime } from 'luxon';

interface IMeetingPrintVersion {
  meeting?: IMeeting;
  ref: React.ForwardedRef<unknown>;
  showApprovedPastMeetingsToApprove?: boolean;
}

export const MeetingPrintVersion: React.FC<IMeetingPrintVersion> = forwardRef((props, ref) => {
  const { meeting, showApprovedPastMeetingsToApprove } = props;

  const { workspace } = useWorkspaceShow();

  const { t } = useLocale();

  const committeeType = useCommitteeName(meeting?.committeeId as string);

  const showMeetingUrlField = meeting?.location?.type === 'hybrid' || meeting?.location?.type === 'remote';
  const showMeetingAddressField =
    meeting?.location?.type === 'hybrid' || meeting?.location?.type === 'inPerson';

  if (!meeting) return null;

  return (
    <Box className="printWrapper" sx={{ display: 'block' }} ref={ref}>
      <>
        {/* LOGO (if they have one) */}
        {workspace?.icon && (
          <Box>
            <img
              src={workspace.icon}
              alt="Logo"
              style={{ maxHeight: '50px', maxWidth: '200px', marginTop: '6px', marginLeft: '14px' }}
            />
          </Box>
        )}

        {/* Meeting Info Card */}
        <Box
          sx={{
            py: 1,
            px: { xs: 2, md: 3 },
            my: 2
          }}
        >
          <Stack sx={{ flexGrow: 1 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 3
              }}
            >
              {/* Title + Meeting type */}
              <Typography variant="h6">
                {meeting.title} | {committeeType && committeeType}
              </Typography>
            </Box>

            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0.5, md: 5 }}>
              {/* date */}
              <Stack direction="row" spacing={2} alignItems="center">
                <CalendarTodayIcon color="action" />
                <Typography variant="body2" sx={{ opacity: '0.6' }}>
                  <DateTimeStartEnd
                    isoStringDate={
                      meeting.startDateTime ||
                      (meeting?.startDate
                        ? DateTime.fromFormat(`${meeting.startDate}`, 'yyyy-MM-dd').toString()
                        : undefined)
                    }
                    separator=" | "
                    dateFormat="DATE_HUGE"
                    isoStringStart={meeting.startDateTime}
                    isoStringEnd={meeting.endDateTime}
                  />
                </Typography>
              </Stack>
            </Stack>

            {/* Meeting location, URL and Address */}
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'left', md: 'center' }}
              spacing={{ xs: 2, md: 5 }}
              sx={{ mt: 2 }}
            >
              <Typography variant="body2" sx={{ opacity: '0.6' }}>
                {meeting.location?.type ? (
                  t(`common:locationType.${meeting.location.type}`)
                ) : (
                  <i>{t('common:noLocation')}</i>
                )}
              </Typography>

              {showMeetingUrlField && meeting.location?.url && (
                <>
                  <Typography variant="body2" sx={{ display: { xs: 'none', md: 'block' } }}>
                    &#183;
                  </Typography>
                  <Box>
                    <Link
                      href={meeting.location?.url}
                      sx={{ display: 'flex', alignItems: 'center' }}
                      target="_blank"
                      rel="noopener"
                      color="link.main"
                      variant="body2"
                    >
                      <OpenInNewIcon fontSize="small" sx={{ mr: 0.5 }} />
                      {meeting.location?.url}
                    </Link>
                  </Box>
                </>
              )}
              {showMeetingAddressField && meeting.location?.address && (
                <>
                  <Typography variant="body2" sx={{ display: { xs: 'none', md: 'block' } }}>
                    &#183;
                  </Typography>
                  <Typography
                    sx={{ display: 'inline' }}
                    variant="body2"
                    component="span"
                    color="text.primary"
                  >
                    <Box sx={{ whiteSpace: 'pre-wrap', display: 'flex', alignItems: 'start', mt: 0.5 }}>
                      <LocationOnIcon fontSize="small" sx={{ mt: '2px', mr: 0.5, opacity: '0.6' }} />
                      <Box>{meeting.location?.address}</Box>
                    </Box>
                  </Typography>
                </>
              )}
            </Stack>

            {meeting?.participants && meeting.participants?.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  {t('common:participants')}
                </Typography>
                <ParticipantsList participants={meeting.participants} chipVariant="outlined" sx={{ mb: 0 }} />
              </Box>
            )}
          </Stack>
        </Box>

        <Divider sx={{ mx: 3 }} />

        {/* Topic Cards */}
        <Stack sx={{ mb: 6.25 }}>
          {meeting.topics
            ?.filter((t) => !t.deletedAt)
            .map((topic, index, array) => {
              return (
                <Box key={topic.id}>
                  <TopicCardMeetingPrint
                    topic={topic}
                    order={index + 1}
                    showApprovedPastMeetingsToApprove={showApprovedPastMeetingsToApprove ? true : false}
                  />
                  {index !== array.length - 1 && <Divider sx={{ mx: 3, mb: 0 }} />}
                </Box>
              );
            })}
        </Stack>
      </>
    </Box>
  );
});
