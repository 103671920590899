import { IDocument, IDocumentRecord } from 'types';
import { useLocale } from '@koopajs/react';
import { Typography, Box, Stack, Button, Skeleton, Paper, List, ListItem, ListItemText } from '@mui/material';
import prettyBytes from 'pretty-bytes';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import DownloadIcon from '@mui/icons-material/Download';
import { Link as RouterLink } from 'react-router-dom';
import { RenderDocumentIcon } from 'components/RenderDocumentIcon';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { SystemStyleObject } from '@mui/system';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { getCurrentUserRoles } from 'utils/getCurrentUserRoles';
import { useUserShow } from '@koopajs/react';
import { useCallback, useState } from 'react';
import { DeleteHistoricalDocument } from 'components/Modals/DeleteHistoricalDocument';
import { formatDate } from 'utils/DateTime/formatDate';
import { DocumentAnnotation } from 'components/DocumentAnnotation';
import { theme } from 'components/Core/theme';
import { useIsDocumentDownloadBlocked } from 'components/hooks/useIsDocumentDownloadBlocked';

interface IDocumentAttachedToDocumentRecord {
  document: IDocument;
  documentRecord?: IDocumentRecord;
  documentIsProcessing: boolean;
  documentRecordIsProcessing: boolean;
}

export const DocumentAttachedToDocumentRecord: React.FC<IDocumentAttachedToDocumentRecord> = (props) => {
  const { document, documentRecord, documentIsProcessing, documentRecordIsProcessing } = props;

  const { t, locale } = useLocale();
  const keyPrefix = 'DocumentAttachedToDocumentRecord';
  const { user: currentUser } = useUserShow();

  const committeeName = useCommitteeName(document.attachedToDocumentRecord?.committeeId as string);

  const isUserDocumentRecordCreatorRole = getCurrentUserRoles({
    activeCommitteeMembers: documentRecord?.activeCommitteeMembers,
    userId: currentUser?.id
  }).includes('createDocumentRecords');

  const canUserDeleteDocument =
    isUserDocumentRecordCreatorRole && document.id !== documentRecord?.lastDocumentId;

  const [isDeleteDocumentRecordOpen, setIsDeleteDocumentRecordOpen] = useState(false);

  const handleOpenDeleteModal = useCallback(() => {
    setIsDeleteDocumentRecordOpen(true);
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteDocumentRecordOpen(false);
  }, []);

  const isDocumentDownloadBlocked = useIsDocumentDownloadBlocked();

  const isPdf = document?.attachedFile.format === 'application/pdf';
  const isImage = document && document?.attachedFile.format.startsWith('image');

  const renderLastUpdatedTimestamp = ({ sx }: { sx: SystemStyleObject }): React.ReactNode => {
    return (
      <Typography sx={{ opacity: 0.6, ...sx }}>
        {t(keyPrefix + '.lastUpdate')}
        {document && !documentIsProcessing ? (
          <DateTimeStartEnd
            isoStringDate={document.$createdAt}
            dateFormat="DATE_FULL"
            separator={` ${t('common:at')} `}
            isoStringStart={document.$createdAt}
          />
        ) : (
          <Skeleton variant="rounded" sx={{ display: 'inline-block' }}>
            <DateTimeStartEnd
              isoStringDate={'1970-01-01T00:00:00.000Z'}
              dateFormat="DATE_FULL"
              separator={` ${t('common:at')} `}
              isoStringStart={'1970-01-01T00:00:00.000Z'}
            />
          </Skeleton>
        )}
      </Typography>
    );
  };

  return (
    <>
      <Box sx={{ mx: { lg: 18 }, display: 'flex', flexDirection: 'column', gap: 3 }}>
        {/* HEADER */}
        <Box data-cy="historical-document-record_header">
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" gap={2}>
            <Stack gap={2} sx={{ minWidth: 0, justifyContent: 'space-between' }}>
              {/* date  mobile */}
              {renderLastUpdatedTimestamp({
                sx: { display: { xs: 'block', sm: 'none' }, alignSelf: 'flex-end' }
              })}

              <Box sx={{ display: { xs: 'flex', sm: 'block' }, gap: 2 }}>
                {/* back arrow button mobile*/}
                <Button
                  component={RouterLink}
                  to={`/document-records/${document.attachedToDocumentRecord?.documentRecordId}`}
                  variant="outlined"
                  sx={{ minWidth: 'auto', px: '5px', display: { sm: 'none' } }}
                  aria-label={t('common:labelBack')}
                >
                  <ArrowBackIcon sx={{ color: 'primary.main' }} />
                </Button>
                {/* title */}
                <Typography
                  variant="h6"
                  component="h1"
                  sx={{ fontSize: '24px', display: 'flex', alignItems: 'center', gap: 1, minWidth: '0px' }}
                  className="rr-mask"
                >
                  <Box
                    component="span"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      minWidth: '0px',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {document.attachedToDocumentRecord?.title}
                  </Box>
                </Typography>
              </Box>

              {/* filename & size */}
              <Typography
                sx={{
                  opacity: 0.6,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                {document && !documentIsProcessing ? (
                  <>
                    <RenderDocumentIcon fileType={document?.attachedFile.format} />
                    <Box
                      component="span"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        minWidth: '0px',
                        whiteSpace: 'nowrap'
                      }}
                      data-cy="document-record-view_file-name"
                      className="rr-mask"
                    >
                      {document.attachedFile.name}
                    </Box>
                  </>
                ) : (
                  <>
                    <Skeleton
                      variant="circular"
                      sx={{ width: '24px', height: '24px', display: 'inline-block' }}
                    />
                    <Skeleton variant="rounded" sx={{ display: 'inline-block' }}>
                      <span>loading_file.pdf</span>
                    </Skeleton>
                  </>
                )}
                <span>&#183;</span>
                {document && !documentIsProcessing ? (
                  <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
                    {prettyBytes(document.attachedFile.sizeBytes, { maximumFractionDigits: 0 })}
                  </Box>
                ) : (
                  <Skeleton variant="rounded" sx={{ display: 'inline-block' }}>
                    <span>00 kB</span>
                  </Skeleton>
                )}
              </Typography>
            </Stack>

            <DeleteHistoricalDocument
              document={document}
              isOpen={isDeleteDocumentRecordOpen}
              onClose={handleCloseDeleteModal}
            />
            <Stack alignItems="flex-end" gap={3}>
              {/* date  desktop */}
              {renderLastUpdatedTimestamp({
                sx: { display: { xs: 'none', sm: 'block' } }
              })}

              {!isDocumentDownloadBlocked &&
                document?.attachedFile.format !== 'application/pdf' &&
                !documentIsProcessing && (
                  <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    href={`/documents/${document?.id}`}
                    target="_blank"
                    rel="noopener"
                    data-cy="document-record_download-button"
                    sx={{ minWidth: 'max-content' }}
                  >
                    {t(keyPrefix + '.downloadDocumentButtonLabel')}
                  </Button>
                )}
            </Stack>
          </Stack>
          {isDocumentDownloadBlocked && !isPdf && (
            <Typography variant="caption" sx={{ display: 'block', opacity: 0.6 }}>
              {t('common:downloadBlockedDocumentMessage')}
            </Typography>
          )}
        </Box>
        {/* PDF VIEWER */}

        {isPdf && (
          <Box className="rr-block">
            <DocumentAnnotation
              documentId={document.id}
              sx={{
                height: '700px'
              }}
              testId="document-record_file-iframe"
            />
          </Box>
        )}
        {isImage && !isDocumentDownloadBlocked && (
          <Box className="rr-block">
            <iframe
              style={{
                border: `1px solid ${theme.palette.customGrey?.light}`,
                backgroundColor: 'rgba(0, 0, 0, 0.11)',
                width: '100%',
                height: '700px'
              }}
              className="rr-block"
              src={document.attachedFileUri}
              data-cy="document-record_file-iframe"
            />
          </Box>
        )}

        {/* DOCUMENT DETAILS CARD */}
        <Paper
          variant="outlined"
          sx={{ p: { md: 3, xs: 2 }, display: 'flex', flexDirection: 'row', minWidth: '0px' }}
          data-cy="document-details-card"
        >
          <List sx={{ width: '100%', minWidth: '0px', p: 0 }} data-cy="document-details-card_title">
            <ListItem sx={{ px: 0 }}>
              {/* title */}
              <ListItemText
                primary={t('common:title')}
                primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                sx={{ overflow: 'hidden', px: 0 }}
                secondary={
                  <Typography
                    sx={{
                      display: 'inline-block',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '100%'
                    }}
                    component="span"
                    color="text.primary"
                    className="rr-mask"
                  >
                    {document.attachedToDocumentRecord?.title}
                  </Typography>
                }
              />
            </ListItem>
            {/* committee */}
            <ListItem sx={{ px: 0 }} data-cy="document-details-card_group">
              <ListItemText
                primary={t(keyPrefix + '.labelGroup')}
                primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                secondary={
                  <Typography
                    sx={{
                      display: 'inline',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      minWidth: '0px'
                    }}
                    component="span"
                    color="text.primary"
                  >
                    {committeeName}
                  </Typography>
                }
                sx={{ px: 0 }}
              />
            </ListItem>
            {/* category */}
            <ListItem sx={{ px: 0 }} data-cy="document-details-card_category">
              <ListItemText
                primary={t(keyPrefix + '.labelCategory')}
                primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                secondary={
                  <Typography
                    sx={{
                      display: 'inline',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      minWidth: '0px'
                    }}
                    component="span"
                    color="text.primary"
                  >
                    {documentRecordIsProcessing ? (
                      <Skeleton variant="rounded" sx={{ display: 'inline-block' }}>
                        <span>this is a category placeholder</span>
                      </Skeleton>
                    ) : documentRecord?.category ? (
                      t(`common:documentRecordCategories.${documentRecord.category}`)
                    ) : null}
                  </Typography>
                }
                sx={{ px: 0 }}
              />
            </ListItem>

            {document.attachedToDocumentRecord?.activeSince && (
              <ListItem
                sx={{ px: 0, width: 'auto', mr: { xs: 4, sm: 0 } }}
                data-cy="document-details-card_last-reviewed-at"
              >
                <ListItemText
                  primary={t(keyPrefix + '.labelActiveSince')}
                  primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                  secondary={
                    <Typography
                      sx={{
                        display: 'inline',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        minWidth: '0px'
                      }}
                      component="span"
                      color="text.primary"
                    >
                      {formatDate({
                        isoString: document.attachedToDocumentRecord.activeSince,
                        locale,
                        isUTC: true
                      })}
                    </Typography>
                  }
                  sx={{ px: 0 }}
                />
              </ListItem>
            )}
          </List>
        </Paper>
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: isUserDocumentRecordCreatorRole ? 'space-between' : 'flex-end',
              flexDirection: { xs: 'row-reverse', sm: 'row' }
            }}
          >
            {/* delete button */}
            {canUserDeleteDocument && (
              <Button
                color="error"
                onClick={handleOpenDeleteModal}
                data-cy="historical-document_delete-button"
              >
                {t(keyPrefix + '.labelDeleteDocument')}
              </Button>
            )}
            {/* desktop 'back' button */}
            <Button
              component={RouterLink}
              to={`/document-records/${document.attachedToDocumentRecord?.documentRecordId}`}
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              {t('common:labelBack')}
            </Button>
            {/* mobile back arrow button */}
            <Button
              component={RouterLink}
              to={`/document-records/${document.attachedToDocumentRecord?.documentRecordId}`}
              variant="outlined"
              sx={{ minWidth: 0, px: '5px', display: { sm: 'none' } }}
              aria-label={t('common:labelBack')}
            >
              <ArrowBackIcon sx={{ color: 'primary.main' }} />
            </Button>
          </Box>
        </>
      </Box>
    </>
  );
};
