import { Box, Typography, IconButton, List, ListItem, ListItemText, Paper } from '@mui/material';
import {
  useLocale,
  useResourceCreate,
  useFileUpload,
  useResourceShow,
  useResourceUpdate,
  useResourceDelete
} from '@koopajs/react';
import { RenderDocumentIcon } from 'components/RenderDocumentIcon';
import prettyBytes from 'pretty-bytes';
import { FormControllerTemp } from 'components/temp/FormControllerTemp';
import { ICommittee, IDocument, IUploadedMinute } from 'types';
import { useState, useCallback, useEffect } from 'react';
import { UploadedMinuteCardEditable } from './UploadedMinuteCardEditable';
import { Edit as EditIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { usePermissions, useAppContext } from '@koopajs/react';
import { formatDate } from 'utils/DateTime/formatDate';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { DateTime } from 'luxon';
import { ErrorMessageTemp } from 'components/temp/ErrorMessageTemp';
import { DocumentList } from 'components/DocumentList';
import { useAppSnackbar } from 'components/hooks/useAppSnackbar';

export interface IFile {
  id?: string;
  blobUrl?: string;
  file: File;
}

interface IUploadedMinuteCardProps {
  file: IFile;
  committees: ICommittee[];
  index: number;
}

export const UploadedMinuteCard: React.FC<IUploadedMinuteCardProps> = (props) => {
  const {
    file: { file, blobUrl, id: fileId },
    committees,
    index
  } = props;
  const { t, locale } = useLocale();

  const titleWithoutFileFormat = file.name ? file?.name.replace(/\.[^/.]+$/, '') : undefined;

  const keyPrefix = 'UploadedMinutes.UploadedMinutesCard';

  const { getComponentContext, setComponentContext } = useAppContext();
  const { files } = getComponentContext('uploadedMinutes') as { files: IFile[] };

  const [isInEditMode, setIsInEditMode] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  const { triggerSnackbar } = useAppSnackbar();

  const fileUpload = useFileUpload();

  const { resource: uploadedMinute } = useResourceShow<IUploadedMinute>({
    path: '/uploaded-minutes',
    id: fileId
  });

  const { resource: mainDocument } = useResourceShow<IDocument>({
    path: '/documents',
    id: uploadedMinute?.mainDocumentId
  });

  const { createResource: createUploadedMinute, errorMessage } = useResourceCreate({
    path: '/uploaded-minutes'
  });

  const {
    updateResource: updatedUploadedMinute,
    errorMessage: errorMessageUpdate,
    isProcessing: isUpdateUploadedMinuteProcessing
  } = useResourceUpdate({
    path: '/uploaded-minutes',
    id: uploadedMinute?.id as string
  });

  const {
    deleteResource: deleteUploadedMinute,
    errorMessage: errorMessageDelete,
    isProcessing: isDeleteUploadedMinuteProcessing
  } = useResourceDelete({
    path: '/uploaded-minutes',
    id: uploadedMinute?.id as string
  });

  const committeeName = useCommitteeName(uploadedMinute?.committeeId as string);

  const isPdf = Boolean(
    mainDocument?.attachedFile?.format === 'application/pdf' || file.type === 'application/pdf'
  );
  const isImage = Boolean(
    mainDocument?.attachedFile?.format.startsWith('image') || file.type.startsWith('image')
  );
  const isPdfOrImage = isPdf || isImage;

  const handleSetToEditMode = useCallback(() => {
    return () => setIsInEditMode(true);
  }, []);

  // SAVE UPLOADED MINUTE
  const handleSubmitCreate = useCallback(
    async (data: object) => {
      const typedData = data as {
        committeeId: string;
        title: string;
        startDateTime: string;
      };

      setIsProcessing(true);

      const fileUri = await fileUpload.upload(file);

      const payload = {
        ...typedData,
        startDateTime: typedData.startDateTime
          ? new Date(typedData.startDateTime + `T00:00:00Z`).toISOString()
          : '',
        attachedFile: {
          uri: fileUri,
          format: file.type,
          name: file.name,
          sizeBytes: file.size
        }
      };

      let uploadedDocumentId = '';
      const isSuccess = await createUploadedMinute(payload, (id) => {
        uploadedDocumentId = id;
      });
      setIsProcessing(false);

      if (isSuccess) {
        setIsInEditMode(false);

        const updatedFiles = files.map((f, i) => {
          if (f.file.lastModified === file.lastModified && f.file.name === file.name && i === index) {
            const updatedFile = Object.assign(f, { id: uploadedDocumentId });

            return updatedFile;
          } else {
            return f;
          }
        });

        setComponentContext('uploadedMinutes', { files: updatedFiles });
      }

      return isSuccess;
    },
    [file, files]
  );

  // UPDATE UPLOADED MINUTE
  const handleSubmitUpdate = useCallback(
    async (data: object) => {
      const typedData = data as {
        committeeId: string;
        title: string;
        startDateTime: string;
      };

      const payload = {
        ...typedData,
        startDateTime: typedData.startDateTime
          ? new Date(typedData.startDateTime + `T00:00:00Z`).toISOString()
          : ''
      };

      const isSuccess = await updatedUploadedMinute(payload);

      if (isSuccess) {
        setIsInEditMode(false);
      }

      return isSuccess;
    },
    [uploadedMinute?.id]
  );

  // DELETE UPLOADED MINUTE
  const handleDeleteUploadedMinute = useCallback(async () => {
    if (uploadedMinute?.id) {
      const isSuccess = await deleteUploadedMinute();
      if (isSuccess) {
        const updatedFiles = files.filter((f, i) => f.id !== uploadedMinute?.id);

        setComponentContext('uploadedMinutes', { files: updatedFiles });

        triggerSnackbar({
          snackbarText: t(keyPrefix + '.snackbarDeleteSuccess'),
          variant: 'success'
        });
      } else {
        triggerSnackbar({
          snackbarText: t(keyPrefix + '.snackbarDeleteFail'),
          variant: 'error'
        });
      }
    } else {
      const updatedFiles = files.filter((f, i) => i !== index && f.file.name !== file.name);
      setComponentContext('uploadedMinutes', { files: updatedFiles });

      triggerSnackbar({
        snackbarText: t(keyPrefix + '.snackbarDeleteSuccess'),
        variant: 'success'
      });
    }
  }, [deleteUploadedMinute, uploadedMinute?.id, JSON.stringify(files)]);

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: '24px',
        my: 2
      }}
      data-cy="uploaded-minute-card"
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3
          }}
        >
          {/* PDF VIEWER */}

          {isPdfOrImage && (mainDocument?.attachedFileUri || blobUrl) && (
            <Box sx={{ width: { xs: '100%', md: '40%' } }}>
              <iframe
                style={{
                  border: '1px solid gray',
                  backgroundColor: 'rgba(0, 0, 0, 0.11)',
                  width: '100%',
                  height: '100%'
                }}
                className="rr-block"
                src={mainDocument?.attachedFileUri || blobUrl}
                data-cy=""
              />
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', md: isPdfOrImage || blobUrl ? '60%' : '100%' },
              gap: 3
            }}
          >
            <ErrorMessageTemp error={errorMessage || errorMessageUpdate || errorMessageDelete} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  minWidth: '0px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden'
                  }}
                >
                  {(mainDocument?.attachedFile?.format || file?.type) && (
                    <RenderDocumentIcon
                      fileType={mainDocument?.attachedFile?.format || file.type}
                      sx={{ mr: 1 }}
                    />
                  )}
                  <Typography
                    variant="body2"
                    display="inline"
                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    data-cy="uploaded-minute-card_file-name"
                    className="rr-mask"
                  >
                    {mainDocument?.attachedFile?.name || file?.name}
                  </Typography>
                </Box>

                <span>&#183;</span>
                <Typography variant="body2" sx={{ opacity: 0.6, flex: 'none' }}>
                  {(mainDocument?.attachedFile?.sizeBytes || file?.size) &&
                    prettyBytes(mainDocument?.attachedFile.sizeBytes || file.size)}
                </Typography>
              </Box>
              {!isInEditMode && isUserWorkspaceOwner && (
                <IconButton
                  data-cy="uploaded-minute-card_edit-button"
                  sx={{ alignSelf: 'flex-start', ml: 2 }}
                  onClick={handleSetToEditMode()}
                >
                  <EditIcon />
                </IconButton>
              )}
            </Box>
            {isInEditMode && isUserWorkspaceOwner ? (
              <FormControllerTemp
                onSubmit={uploadedMinute?.id ? handleSubmitUpdate : handleSubmitCreate}
                defaultValues={{
                  title: uploadedMinute?.title || titleWithoutFileFormat,
                  startDateTime:
                    uploadedMinute?.startDateTime &&
                    DateTime.fromISO(uploadedMinute?.startDateTime, { zone: 'utc' }).toFormat('yyyy-MM-dd'),
                  committeeId: uploadedMinute?.committeeId
                }}
              >
                <UploadedMinuteCardEditable
                  committees={committees}
                  uploadedMinuteId={uploadedMinute?.id}
                  handleDeleteUploadedMinute={handleDeleteUploadedMinute}
                  isProcessing={isProcessing || isUpdateUploadedMinuteProcessing}
                  isPdf={isPdf}
                />
              </FormControllerTemp>
            ) : (
              <>
                <List>
                  <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
                    <ListItem alignItems="flex-start" sx={{ px: 0 }} data-cy="uploaded-minute-card_date-li">
                      <ListItemText
                        primary={t(keyPrefix + '.meetingDate')}
                        primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                        secondary={
                          <Typography
                            sx={{
                              display: 'inline',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              minWidth: '0px'
                            }}
                            component="span"
                            variant="body1"
                            color="text.primary"
                          >
                            {uploadedMinute &&
                              formatDate({
                                isoString: uploadedMinute?.startDateTime as string,
                                locale,
                                format: 'DATE_FULL',
                                isUTC: true
                              })}
                          </Typography>
                        }
                        sx={{ px: 0 }}
                      />
                    </ListItem>

                    <ListItem
                      alignItems="flex-start"
                      sx={{ px: 0 }}
                      data-cy="uploaded-minute-card_committee-li"
                    >
                      <ListItemText
                        primary={t(keyPrefix + '.meetingType')}
                        primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                        secondary={
                          <Typography
                            sx={{
                              display: 'inline',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              minWidth: '0px'
                            }}
                            component="span"
                            variant="body1"
                            color="text.primary"
                          >
                            {uploadedMinute && committeeName}
                          </Typography>
                        }
                        sx={{ px: 0 }}
                      />
                    </ListItem>
                  </Box>
                  <ListItem alignItems="flex-start" sx={{ px: 0 }} data-cy="uploaded-minute-card_title-li">
                    <ListItemText
                      primary={t(keyPrefix + '.title')}
                      primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                      secondary={
                        <Typography
                          sx={{
                            display: 'inline',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            minWidth: '0px',
                            whiteSpace: 'nowrap'
                          }}
                          component="span"
                          variant="body1"
                          color="text.primary"
                        >
                          {uploadedMinute && uploadedMinute.title}
                        </Typography>
                      }
                      sx={{ px: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    />
                  </ListItem>
                </List>
              </>
            )}
            {uploadedMinute?.id && !isInEditMode && (
              <Box
                sx={{
                  display: 'flex',
                  gap: { xs: 2, md: 0 },
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: {
                    xs: 'flex-start',
                    md: 'space-between'
                  }
                }}
              >
                <Box sx={{ minWidth: 0 }}>
                  <DocumentList
                    path={`/uploaded-minutes/${uploadedMinute?.id}/documents`}
                    isAccordion={true}
                    accordionOptions={{ documentsVisible: 2 }}
                    isEditable={isUserWorkspaceOwner && Boolean(uploadedMinute?.id)}
                    sxDocument={{ display: 'flex' }}
                  />
                </Box>

                <Box
                  sx={{ display: 'flex', py: '6px', alignSelf: { md: 'flex-end' }, minWidth: 'fit-content' }}
                >
                  <CheckCircleIcon color="success" />
                  <Typography variant="body1" sx={{ opacity: '0.6', ml: 1 }}>
                    {t(keyPrefix + '.addedToBooks')}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
