/* eslint-disable react/jsx-no-bind */
import { IMeeting } from 'types';
import { useCallback, useRef, useState } from 'react';
import {
  useResourceList,
  useUserShow,
  useWorkspaceShow,
  useLocale,
  useComponentVisibility,
  useResourceShow,
  useParamsKey
} from '@koopajs/react';
import {
  Button,
  Box,
  Stack,
  Typography,
  Paper,
  Divider,
  Tooltip,
  IconButton,
  ClickAwayListener,
  List,
  ListItem
} from '@mui/material';
import { IUserPublicProfile } from '@koopajs/app';
import { calculateMeetingDuration } from 'utils/calculateMeetingDuration';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { DeleteMeeting } from '../components/Modals/DeleteMeeting';
import { checkIsEndTopic, checkIsUnmovableTopicType } from 'utils/topicTypeArrays';
import { AddTopicButton } from '../components/Meetings/AddTopicButton';
import { MeetingEditHeaderCard } from '../components/Meetings/Edit/MeetingEditHeaderCard';
import { MeetingStateChip } from '../components/Meetings/MeetingStateChip';
import { MeetingTopicCardEditableMovable } from '../components/Meetings/MeetingTopicCardEditable/MeetingTopicCardEditableMovable';
import { MeetingPrintVersion } from '../components/Meetings/MeetingPrintVersion';
import { RenderPrintButton } from 'components/RenderPrintButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { generateMeetingTimeline } from 'utils/generateMeetingTimeline';
import { VisibleByAccordion } from 'components/VisibleByAccordion';
import { PageContainer } from 'components/temp/PageContainer';
import { calculateTopicsStartTime } from 'utils/calculateTopicsStartTime';
import { ListAlt as ListAltIcon } from '@mui/icons-material';
import { StartMeetingBanner } from 'components/Meetings/StartMeetingBanner';
import { EventsTimeline } from 'components/EventsTimeline';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';
import { MeetingLoadingOrError } from 'components/Meetings/MeetingLoadingOrError';
import { Loading } from '@koopajs/mui';
import { Trans } from 'react-i18next';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { LightBulbIconButton } from 'components/LightBulbIconButton';
import { theme } from 'components/Core/theme';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { getMeetingPdfDocuments } from 'utils/getMeetingPdfDocuments';

export const i18nTransComponents: { [k: string]: React.ReactElement } = {
  span: <span style={{ color: 'grey' }} />
};

export const MeetingEdit: React.FC = (props) => {
  const { t } = useLocale();

  const meetingId = useParamsKey('meetingId');
  const {
    resource: meeting,
    isProcessing,
    errorMessage
  } = useResourceShow<IMeeting>({
    path: '/meetings',
    id: meetingId
  });

  const { user: currentUser } = useUserShow();

  const history = useHistory();

  const { isUserAuthorizedEditor } = useMeetingStatus({ meeting, user: currentUser });

  const meetingPdfDocuments = getMeetingPdfDocuments(meeting?.id);

  /*
  REDIRECT
  */
  const doesUserHaveAccessToPage = isUserAuthorizedEditor && !meeting?.minutesStartedAt;
  if (meeting && !doesUserHaveAccessToPage) {
    history.push(`/meetings/${meetingId}`);
  }

  const { resources: users } = useResourceList<IUserPublicProfile>({
    path: '/users',
    searchParams: { size: 50 }
  });
  const workspace = useWorkspaceShow();

  const sendMeetingDialog = useComponentVisibility('sendMeeting');

  const keyPrefix = 'MeetingEdit';

  const [isMeetingEditable, setMeetingEditable] = useState(false);
  const [topicIdEditable, setTopicIdEditable] = useState('');
  const [isDeleteMeetingOpen, setIsDeleteMeetingOpen] = useState(false);
  const [hasClickedSendMeeting, setHasClickedSendMeeting] = useState(false);
  const handleSetTopicEditable = useCallback((topicId: string) => {
    return () => setTopicIdEditable(topicId);
  }, []);
  const handleUnsetTopicEditable = useCallback(() => {
    setTopicIdEditable('');
  }, []);
  const handleOpenDeleteModal = useCallback(() => {
    setIsDeleteMeetingOpen(true);
  }, []);
  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteMeetingOpen(false);
  }, []);

  const [isGuideTooltipOpen, setIsGuideTooltipOpen] = useState(false);
  const handleGuideTooltipClose = () => {
    setIsGuideTooltipOpen(false);
  };
  const handleGuideTooltipOpen = () => {
    setIsGuideTooltipOpen(true);
  };

  const requiredPropertiesErrorMessages: string[] = [
    'missing_location_type',
    'missing_location_address',
    'missing_participants',
    'missing_date',
    'missing_time_start',
    'missing_time_end',
    'missing_date_time'
  ];

  const isMissingRequiredProperties = meeting?.errorMessages?.some((errorMessage) =>
    requiredPropertiesErrorMessages.includes(errorMessage)
  );
  const handleShowSendMeeting = useCallback(
    (value: boolean) => {
      return () => {
        setHasClickedSendMeeting(true);

        if (!isMissingRequiredProperties) {
          sendMeetingDialog.setVisibleWithContext({
            meeting: meeting,
            defaultValues: { title: meeting?.title }
          });
        }
      };
    },
    [isMissingRequiredProperties, JSON.stringify(meeting)]
  );

  const topics = meeting && calculateTopicsStartTime(meeting);

  const filteredTopicsStart = topics?.filter((topic) => !checkIsEndTopic(topic.type));
  const filteredTopicsEnd = topics?.filter((topic) => checkIsEndTopic(topic.type));

  const notEndTopicCount =
    meeting?.topics?.reduce((acc, topic) => {
      return !checkIsEndTopic(topic.type) ? acc + 1 : acc;
    }, 0) || 0;

  const renderSendMeetingButton = (props?: { sxButton?: SxProps<Theme> }): React.ReactNode => {
    const { sxButton } = props || {};

    return (
      <>
        <Tooltip title={isMissingRequiredProperties ? t(keyPrefix + '.tooltipMissingRequiredFields') : ''}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              onClick={handleShowSendMeeting(true)}
              variant={meeting?.invitationSentAt ? 'outlined' : 'contained'}
              data-cy="meeting-create-send-button"
              sx={{ ...sxButton }}
            >
              {meeting?.invitationSentAt
                ? t(keyPrefix + '.buttonLabelResendMeeting')
                : t(keyPrefix + '.buttonLabelSendMeeting')}
            </Button>
          </Box>
        </Tooltip>
      </>
    );
  };

  const printComponentRef = useRef(null);

  const meetingParticipants = users.filter((user) =>
    meeting?.participants?.find((participant) => participant.userId === user.id)
  );

  const timelineEvents = generateMeetingTimeline(meeting);

  if (!meeting) {
    return <MeetingLoadingOrError isProcessing={isProcessing} errorMessage={errorMessage} />;
  } else if (!doesUserHaveAccessToPage) {
    // loading component while we wait for the redirect to the view page
    return (
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'static' }} />
      </Stack>
    );
  }

  return (
    <>
      <Box sx={{ display: 'none' }}>
        <MeetingPrintVersion meeting={{ ...meeting, topics }} ref={printComponentRef} />
      </Box>
      <PageContainer
        testId="meeting-edit-page"
        sxChildren={{ padding: { xs: 3, md: '24px 56px' } }}
        className="rr-block"
      >
        <Breadcrumbs
          hierarchyArray={[{ path: '/', text: t('common:navigation.dashboard') }]}
          pageTitle={meeting?.title || ''}
          sx={{
            display: { xs: 'none', sm: 'block' }
          }}
        />

        <Box>
          <Stack
            direction="row"
            alignItems="center"
            flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
            sx={{ mt: { sm: 3 }, mb: { md: 2 } }}
            gap={{ xs: '14px', sm: 3 }}
          >
            <Button
              component={RouterLink}
              to="/"
              variant="outlined"
              sx={{ minWidth: 0, px: '5px', display: { sm: 'none' }, mr: '14px' }}
              aria-label={t('common:labelBack')}
            >
              <ArrowBackIcon sx={{ color: 'primary.main' }} />
            </Button>
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{
                minWidth: 0
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  display: 'inline-block',
                  fontWeight: 400,
                  fontSize: '24px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                data-cy="meeting-prepare_title"
              >
                {meeting.title || ''}
              </Typography>
              <ClickAwayListener onClickAway={handleGuideTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          p: 0,
                          backgroundColor: 'transparent',
                          maxWidth: '600px',
                          fontWeight: 400
                        }
                      }
                    }}
                    onClose={handleGuideTooltipClose}
                    open={isGuideTooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <Paper sx={{ p: 3, border: `1px solid ${theme.palette.customGrey.light}` }}>
                        <Typography variant="h6">{t(keyPrefix + '.guideTooltip.title')}</Typography>
                        <List
                          sx={{
                            listStyle: 'decimal',
                            listStylePosition: 'inside',
                            fontSize: 'initial',
                            '& > li::marker': { fontWeight: 'bold' }
                          }}
                        >
                          {['bullet1', 'bullet2', 'bullet3', 'bullet4', 'bullet5'].map((bullet) => (
                            <ListItem key={bullet} disablePadding sx={{ display: 'list-item' }}>
                              <Trans i18nKey={keyPrefix + `.guideTooltip.${bullet}`} t={t} />
                            </ListItem>
                          ))}
                        </List>
                      </Paper>
                    }
                  >
                    <Box>
                      <LightBulbIconButton
                        ariaLabel={t(keyPrefix + '.guideTooltip.ariaLabel')}
                        onClick={handleGuideTooltipOpen}
                      />
                    </Box>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </Stack>
            {/* CHIP */}
            {meeting.minutesStartedAt && (
              <MeetingStateChip meeting={meeting} sx={{ ml: { xs: 'auto', sm: 3 } }} />
            )}
            {/* SEND MEETING BUTTON */}
            <Stack
              flexDirection="row"
              gap="20px"
              sx={{
                alignItems: 'center',
                ml: { sm: 'auto' },
                flexBasis: { xs: '100%', sm: 'auto' }
              }}
            >
              <IconButton
                data-cy="meeting-prepare_view-meeting-button"
                aria-label={t('common:viewMeeting')}
                component={RouterLink}
                to={{
                  pathname: `/meetings/${meeting.id}`,
                  state: { activePath: '/' }
                }}
                sx={{ color: 'primary.main' }}
              >
                <VisibilityIcon />
              </IconButton>
              {renderSendMeetingButton({ sxButton: { width: { xs: '100%', sm: 'auto' } } })}
            </Stack>
          </Stack>

          <Box
            sx={{
              background: `linear-gradient(to right, transparent 32px, ${theme.palette.customGrey.light} 8px)`,
              backgroundSize: '40px',
              backgroundRepeat: 'no-repeat'
            }}
          >
            {/* MEETING DETAILS */}
            <Box sx={{ my: 3 }}>
              <MeetingEditHeaderCard
                meeting={meeting}
                users={users}
                showRequiredErrors={hasClickedSendMeeting}
                isMeetingEditable={isMeetingEditable}
                setMeetingEditable={setMeetingEditable}
              />
            </Box>

            {/* AGENDA DETAILS (TOPICS) */}
            <Paper sx={{ mb: 3, pt: 3, pb: 2 }} variant="outlined" data-cy="meeting-prepare_topics">
              <Stack spacing={2}>
                {!meeting.minutesStartedAt && (
                  <Box>
                    <Box sx={{ px: 3, pb: 1 }}>
                      <VisibleByAccordion
                        title={t(keyPrefix + '.titleAgenda')}
                        prefixVisibleToSome={t(keyPrefix + '.prefixAgendaVisibleToSome')}
                        prefixVisibleToAll={t(keyPrefix + '.prefixAgendaVisibleToAll')}
                        chip={
                          meeting.agendaSentAt
                            ? {
                                sx: { backgroundColor: 'greenTwo.60' },
                                label: t(keyPrefix + '.chipAgendaSent')
                              }
                            : {
                                sx: { backgroundColor: 'customGrey.main' },
                                label: t(keyPrefix + '.chipAgendaInPreparation')
                              }
                        }
                        usersVisibleByIds={meeting.meetingCreatorIds}
                        visibleByState={meeting.agendaSentAt ? 'all' : 'some'}
                        // isVisibleByAllMessageHidden={!isUserMeetingCreator}
                        icon={<ListAltIcon />}
                      />
                    </Box>
                    <Divider sx={{ mx: 3 }} />
                  </Box>
                )}
                {filteredTopicsStart?.map((topic, index) => {
                  const previousTopic = topics?.[index - 1];
                  const nextTopic = topics?.[index + 1];
                  const isPreviousTopicMovable = !checkIsUnmovableTopicType(previousTopic?.type || '');
                  const isNextTopicMovable = !checkIsUnmovableTopicType(nextTopic?.type || '');

                  return (
                    <Box key={topic.id} sx={{ mt: '0px !important' }}>
                      <MeetingTopicCardEditableMovable
                        meetingId={meeting.id}
                        topic={topic}
                        handleSetTopicEditable={handleSetTopicEditable}
                        order={index + 1}
                        isEditable={topic.id === topicIdEditable}
                        users={users}
                        participants={meetingParticipants}
                        isMeetingStarted={false}
                        onSubmitSuccess={handleUnsetTopicEditable}
                        previousTopicId={isPreviousTopicMovable ? previousTopic?.id : undefined}
                        nextTopicId={isNextTopicMovable ? nextTopic?.id : undefined}
                        meetingPdfDocuments={meetingPdfDocuments}
                      />

                      <Divider sx={{ mx: { xs: '14px', md: 3 } }} />
                    </Box>
                  );
                })}
                <>
                  <Box sx={{ px: 3, mt: '0 !important' }}>
                    <AddTopicButton
                      meeting={meeting}
                      workspaceId={workspace.workspace?.id}
                      setTopicIdEditable={setTopicIdEditable}
                      sx={{ py: 1 }}
                    />
                    <Divider />
                  </Box>
                </>
                {filteredTopicsEnd?.map((topic, index) => {
                  return (
                    <Box key={topic.id} sx={{ mt: '0px !important' }}>
                      <MeetingTopicCardEditableMovable
                        meetingId={meeting.id}
                        topic={topic}
                        handleSetTopicEditable={handleSetTopicEditable}
                        order={notEndTopicCount + index + 1}
                        isEditable={topic.id === topicIdEditable}
                        users={users}
                        participants={meetingParticipants}
                        isMeetingStarted={false}
                        onSubmitSuccess={handleUnsetTopicEditable}
                        activeCommitteeMembers={meeting.activeCommitteeMembers}
                        meetingPdfDocuments={meetingPdfDocuments}
                      />
                      {index !== filteredTopicsEnd.length - 1 && (
                        <Divider sx={{ mx: { xs: '14px', md: 3 } }} />
                      )}
                    </Box>
                  );
                })}
              </Stack>
            </Paper>

            <StartMeetingBanner meeting={meeting} sx={{ mb: 3 }} />
          </Box>

          <Stack justifyContent="space-between" direction={{ xs: 'column-reverse', md: 'row' }}>
            {/* TIMELINE */}
            <Box sx={{ mt: { xs: 4, md: 0 } }}>
              <EventsTimeline timelineEvents={timelineEvents} />
            </Box>

            {/* MEETING TIME CALCULATION */}
            <Box sx={{ display: 'flex' }}>
              <TimerOutlinedIcon color="action" sx={{ mr: 1 }} />
              <Typography align="right">
                {t(keyPrefix + '.totalMeetingTime')}: {calculateMeetingDuration(t, topics)}
              </Typography>
            </Box>
          </Stack>

          <DeleteMeeting meeting={meeting} isOpen={isDeleteMeetingOpen} onClose={handleCloseDeleteModal} />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              my: 4,
              flexDirection: { xs: 'column', md: 'row' },
              gap: 2
            }}
          >
            <Button
              onClick={handleOpenDeleteModal}
              color="error"
              data-cy="meeting-prepare_delete-meeting-button"
              sx={{ mr: 'auto' }}
            >
              {t(keyPrefix + '.labelDeleteMeeting')}
            </Button>
            <Stack direction="row" gap={2} alignItems={'flex-start'}>
              {/* mobile back */}
              <Button
                component={RouterLink}
                to="/"
                variant="outlined"
                sx={{ minWidth: 0, px: '5px', display: { sm: 'none' }, mr: 'auto' }}
                aria-label={t('common:labelBack')}
              >
                <ArrowBackIcon sx={{ color: 'primary.main' }} />
              </Button>
              {/* desktop back */}
              <Button
                component={RouterLink}
                to="/"
                sx={{ display: { xs: 'none', sm: 'inline-flex' }, mr: { xs: 'auto', md: 0 } }}
              >
                {t('common:labelBack')}
              </Button>
              <RenderPrintButton componentRef={printComponentRef} variant="outlined" />
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>{renderSendMeetingButton()}</Box>
            </Stack>
          </Box>
        </Box>
      </PageContainer>
    </>
  );
};
