import React, { useCallback, useState, useEffect } from 'react';
import { Dialog, Loading } from '@koopajs/mui';
import { useResourceShow, usePermissions } from '@koopajs/react';
import { Box, Typography, Button, IconButton, Paper } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { IUploadedMinute, IDocument } from 'types';
import { EventsTimeline } from 'components/EventsTimeline';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { formatDate } from 'utils/DateTime/formatDate';
import { useLocale } from '@koopajs/react';
import { RenderDocumentIcon } from 'components/RenderDocumentIcon';
import prettyBytes from 'pretty-bytes';
import { DocumentList } from 'components/DocumentList';
import DownloadIcon from '@mui/icons-material/Download';
import { useAppSnackbar } from 'components/hooks/useAppSnackbar';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { Edit as EditIcon } from '@mui/icons-material';
import { UploadedMinutesDialogEditForm } from 'components/UploadedMinutes/UploadedMinutesDialogEditForm';
import { DeleteUploadedMinute } from 'components/Modals/DeleteUploadedMinute';
import { DocumentAnnotation } from 'components/DocumentAnnotation';
import { theme } from 'components/Core/theme';
import { useIsDocumentDownloadBlocked } from 'components/hooks/useIsDocumentDownloadBlocked';

interface IViewUploadedMinutes {
  uploadedMinutesId?: string;
}

export const ViewUploadedMinutes: React.FC<IViewUploadedMinutes> = (props) => {
  const { uploadedMinutesId } = props;

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isDeleteUploadedMinuteOpen, setIsDeleteUploadedMinuteOpen] = useState(false);

  const history = useHistory();

  const { resource: uploadedMinute, errorMessage } = useResourceShow<IUploadedMinute>({
    path: '/uploaded-minutes',
    id: uploadedMinutesId
  });

  const { resource: mainDocument, isProcessing: isDocumentProcessing } = useResourceShow<IDocument>({
    path: '/documents',
    id: uploadedMinute?.mainDocumentId
  });

  const { t, locale } = useLocale();
  const keyPrefix = 'Dialogs.ViewUploadedMinutes';

  const hasDocuments = Boolean(uploadedMinute?.documentsIds?.length);

  const extractedErrorMessage = extractErrorMessage(errorMessage);
  const minutesType = useCommitteeName(uploadedMinute?.committeeId || '');

  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  const timelineEvents = [
    {
      title: t('Components.EventsTimeline.updatedOn') || '',
      timestamp: uploadedMinute?.$updatedAt || ''
    },
    {
      title: t('Components.EventsTimeline.createdOn') || '',
      timestamp: uploadedMinute?.$createdAt || '',
      userId: uploadedMinute?.$ownedBy || ''
    }
  ];

  const isDocumentPdf = mainDocument?.attachedFile?.format === 'application/pdf';
  const isDocumentImage = mainDocument?.attachedFile?.format.startsWith('image');

  const handleCloseDialog = useCallback(() => {
    history.push({ pathname: '/minutes', search: history.location?.search });
    setIsInEditMode(false);
  }, [history]);

  const { triggerSnackbar } = useAppSnackbar();

  useEffect(() => {
    if (errorMessage === "Error 403: You don't have access to these minutes") {
      triggerSnackbar({
        snackbarText: extractedErrorMessage,
        variant: 'error'
      });

      history.push('/minutes');
    }
  }, [errorMessage, triggerSnackbar, uploadedMinutesId]);

  const handleSetToEditMode = useCallback(() => {
    return () => setIsInEditMode(true);
  }, []);

  const handleOpenDeleteModal = useCallback(() => {
    setIsDeleteUploadedMinuteOpen(true);
  }, []);
  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteUploadedMinuteOpen(false);
  }, []);

  const isDocumentDownloadBlocked = useIsDocumentDownloadBlocked();

  return (
    <Dialog.View
      isOpen={Boolean(uploadedMinutesId && uploadedMinute)}
      onClose={handleCloseDialog}
      isCloseVisible={true}
      i18n={{ keyPrefix: keyPrefix + '.DialogView' }}
      footerActions={
        !isDocumentDownloadBlocked && mainDocument?.attachedFile && !isDocumentProcessing ? (
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            href={`/documents/${mainDocument?.id}`}
            target="_blank"
            rel="noopener"
            data-cy=""
          >
            {t(keyPrefix + '.DialogView.downloadButtonLabel')}
          </Button>
        ) : undefined
      }
      dialogProps={{ className: 'rr-block', PaperProps: { 'data-cy': 'uploaded-minute-dialog' } }}
    >
      {isDocumentProcessing ? (
        <Box sx={{ my: 3 }}>
          <Loading sx={{ backgroundColor: 'transparent' }} />
        </Box>
      ) : (
        uploadedMinute &&
        mainDocument?.attachedFile &&
        !isDocumentProcessing && (
          <>
            <Box sx={{ mb: 6.25, display: 'flex', flexDirection: 'column', gap: 3 }}>
              {isDocumentPdf ? (
                <Box>
                  <DocumentAnnotation
                    documentId={mainDocument.id}
                    sx={{
                      height: 'calc(100vh - 64px - 64px - 52.5px)'
                    }}
                  />
                </Box>
              ) : isDocumentImage && !isDocumentDownloadBlocked ? (
                <iframe
                  style={{
                    border: `1px solid ${theme.palette.customGrey?.light}`,
                    backgroundColor: 'rgba(0, 0, 0, 0.11)',
                    width: '100%',
                    height: 'calc(100vh - 64px - 64px - 52.5px)'
                  }}
                  className="rr-block"
                  src={mainDocument?.attachedFileUri}
                  data-cy=""
                />
              ) : (
                <Paper
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: '24px',
                    gap: 2
                  }}
                  variant="outlined"
                >
                  {isDocumentDownloadBlocked ? (
                    <Typography variant="body2" sx={{ opacity: 0.6 }}>
                      {t('common:downloadBlockedDocumentMessage')}
                    </Typography>
                  ) : (
                    <>
                      <DownloadingIcon />
                      <Typography align="center" variant="h5">
                        {t(keyPrefix + '.downloadDocumentTitle')}
                      </Typography>

                      <Button
                        variant="outlined"
                        startIcon={<DownloadIcon />}
                        href={`/documents/${mainDocument?.id}`}
                        target="_blank"
                        rel="noopener"
                        data-cy=""
                      >
                        {t(keyPrefix + '.downloadMinutesButtonLabel')}
                      </Button>
                    </>
                  )}
                </Paper>
              )}

              {isInEditMode && mainDocument ? (
                <UploadedMinutesDialogEditForm
                  uploadedMinute={uploadedMinute}
                  mainDocument={mainDocument}
                  setIsInEditMode={setIsInEditMode}
                />
              ) : (
                <Paper
                  variant="outlined"
                  sx={{
                    padding: '24px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                  }}
                  data-cy="uploaded-minute-dialog_info-card"
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                      variant="h6"
                      sx={{ display: 'flex', gap: 1, flexDirection: { xs: 'column', md: 'row' } }}
                    >
                      <span>{t(keyPrefix + '.minutesLabel')}</span>
                      <Box component="span" sx={{ display: { xs: 'none', md: 'flex' } }}>
                        |
                      </Box>
                      <Box component="span" data-cy="uploaded-minute-dialog_info-card_committee">
                        {minutesType}
                      </Box>
                    </Typography>
                    {!isInEditMode && isUserWorkspaceOwner && (
                      <IconButton
                        sx={{ alignSelf: 'flex-start', ml: 2 }}
                        onClick={handleSetToEditMode()}
                        data-cy="uploaded-minute-dialog_edit-button"
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </Box>

                  <Typography variant="body1" data-cy="uploaded-minute-dialog_info-card_date">
                    {formatDate({
                      isoString: uploadedMinute.startDateTime,
                      format: 'DATE_HUGE',
                      locale,
                      isUTC: true
                    })}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      opacity: '0.6',
                      alignItems: { xs: 'flex-start', md: 'center' },
                      flexWrap: 'wrap',
                      flexDirection: { xs: 'column', md: 'row' },
                      overflow: 'hidden'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%'
                      }}
                      data-cy="uploaded-minute-dialog_info-card_title"
                    >
                      {uploadedMinute.title}
                    </Typography>
                    {mainDocument?.attachedFile && (
                      <>
                        <Box component="span" sx={{ display: { xs: 'none', md: 'flex' } }}>
                          &#183;
                        </Box>
                        <RenderDocumentIcon fileType={mainDocument?.attachedFile?.format} />
                        <Typography
                          variant="body2"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '100%',
                            minWidth: 0
                          }}
                        >
                          {mainDocument?.attachedFile.name}
                        </Typography>
                        <Box component="span" sx={{ display: { xs: 'none', md: 'flex' } }}>
                          &#183;
                        </Box>
                        <Typography variant="body2">
                          {prettyBytes(mainDocument?.attachedFile.sizeBytes)}
                        </Typography>
                      </>
                    )}
                  </Box>
                  {hasDocuments && (
                    <DocumentList
                      path={`/uploaded-minutes/${uploadedMinute.id}/documents`}
                      isAccordion={true}
                      accordionOptions={{
                        defaultExpanded: true,
                        label: t(keyPrefix + '.documentationLabel'),
                        documentsVisible: 2
                      }}
                    />
                  )}
                </Paper>
              )}
            </Box>
            <EventsTimeline timelineEvents={timelineEvents} />
            {isUserWorkspaceOwner && (
              <>
                <DeleteUploadedMinute
                  uploadedMinute={uploadedMinute}
                  isOpen={isDeleteUploadedMinuteOpen}
                  onClose={handleCloseDeleteModal}
                />
                <Button
                  onClick={handleOpenDeleteModal}
                  color="error"
                  sx={{ display: { xs: 'none', sm: 'block' } }}
                  data-cy="uploaded-minute-dialog_delete-button"
                >
                  {t(keyPrefix + '.labelDeleteUploadedMinutes')}
                </Button>
              </>
            )}
          </>
        )
      )}
    </Dialog.View>
  );
};
