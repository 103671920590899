import { Box } from '@mui/material';
import { ITopic } from 'types';
import { BoardMemberViewTopicCardAccordion } from '../Components/Accordion';
import { BoardMemberViewTopicCardAccordionSummaryChildren } from '../Components/AccordionSummaryChildren';
import { PastMinutesItem } from './PastMinutesItem';
import { useParamsKey } from '@koopajs/react';
import { ViewPastMeeting } from 'components/Dialogs/ViewPastMeeting';
import { useBoardMemberViewContext } from 'components/BoardMemberView/BoardMemberViewProvider';
import { useState } from 'react';
import { useDocumentCount } from 'components/hooks/BoardMemberView/useDocumentCount';
import { DocumentList } from '../Components/DocumentList';
import { TopicDescription } from '../Components/TopicDescription';
import { useLocation } from 'react-router-dom';
import { useScreenSize } from 'utils/useScreenSize';
import { useEffect } from 'react';

interface IBoardMemberViewPastMinutesApprovalCard {
  topic: ITopic;
  index: number;
}

export const BoardMemberViewPastMinutesApprovalCard: React.FC<IBoardMemberViewPastMinutesApprovalCard> = (
  props
) => {
  const { topic, index } = props;

  const boardMemberViewContext = useBoardMemberViewContext();
  const { meeting } = boardMemberViewContext;

  const hasPastMinutesToApprove = Boolean(topic.pastMeetingsToApprove?.length);
  const pastMeetingId = useParamsKey('pastMeetingId');

  const [currentVisibleMinuteIndex, setCurrentVisibleMinuteIndex] = useState(0);

  const {
    linkDocumentsCount,
    setLinkDocumentsCount,
    fileDocumentsCount,
    setFileDocumentsCount,
    hasDocuments
  } = useDocumentCount({ topic });

  const hasContent = Boolean(topic.description || hasPastMinutesToApprove || hasDocuments);

  // scroll to the past minute if the url hash is a valid ulid
  const location = useLocation();
  const urlHash = location.hash.slice(1);
  const isHashUlid = /^[0-7][0-9A-HJKMNP-TV-Z]{25}$/i.test(urlHash);
  const { isScreenAboveLg, isMobileScreen } = useScreenSize();

  useEffect(() => {
    if (!urlHash || !isHashUlid) return;

    const hasMatchingDocument = topic?.pastMeetingsToApprove?.some((pM) => pM.meetingId === urlHash);

    if (!hasMatchingDocument) return;

    // delay the scroll to ensure DOM is ready
    const timeoutId = setTimeout(() => {
      const pastMinuteId = urlHash;
      const documentElement = document.getElementById(pastMinuteId);
      const pastMinuteElement = documentElement ? documentElement.getBoundingClientRect().top : 0;

      if (documentElement && isScreenAboveLg) {
        window.scrollTo({
          top: pastMinuteElement - 49, // header accordion height
          behavior: 'smooth'
        });
      } else {
        //mobile we scroll to the topic
        const topicId = documentElement?.dataset?.topicId;
        const offset = isMobileScreen ? 55 : 63; // header height + 1px (so it's highlighted)
        const topicElement = document.getElementById(`topic-card-${topicId}`);

        const topicPosition = topicElement ? topicElement.getBoundingClientRect().top : 0;

        window.scrollTo({
          top: topicPosition - offset,
          behavior: 'smooth'
        });
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [urlHash, isScreenAboveLg, isMobileScreen]);

  return (
    <>
      <ViewPastMeeting pastMeetingId={pastMeetingId} pathOnClose={`/meeting-annotate/${meeting?.id}`} />

      <BoardMemberViewTopicCardAccordion
        topic={topic}
        index={index}
        isDisabled={!hasContent}
        accordionSummary={
          <BoardMemberViewTopicCardAccordionSummaryChildren
            topic={topic}
            index={index}
            pastMinutesToApproveItemIndex={currentVisibleMinuteIndex}
            linkDocumentsCount={linkDocumentsCount}
            fileDocumentsCount={fileDocumentsCount}
          />
        }
        accordionDetails={
          <>
            {topic.description && <TopicDescription description={topic.description} />}

            {hasDocuments && (
              <Box sx={{ mb: '24px' }}>
                <DocumentList
                  path={`/meetings/${topic.meetingId}/topics/${topic.id}/documents`}
                  setLinkDocumentsCount={setLinkDocumentsCount}
                  setFileDocumentsCount={setFileDocumentsCount}
                />
              </Box>
            )}

            {hasPastMinutesToApprove &&
              topic.pastMeetingsToApprove?.map((pastMinutes, i) => {
                const { meetingId } = pastMinutes;
                return (
                  <Box
                    key={meetingId}
                    sx={{
                      p: { xs: '12px 8px', lg: '24px 64px' },
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <PastMinutesItem
                      minutesId={meetingId}
                      setCurrentVisibleMinuteIndex={setCurrentVisibleMinuteIndex}
                      index={i}
                      topicId={topic.id}
                    />
                  </Box>
                );
              })}
          </>
        }
      />
    </>
  );
};
