import { ErrorMessage } from '@koopajs/mui';
import {
  useComponentVisibility,
  useResourceCreate,
  useFileUpload,
  useResourceList,
  useResourceUpdate,
  useWorkspaceShow,
  useSearchParams
} from '@koopajs/react';
import { Button, Box, Typography, Switch, FormControlLabel, Alert } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { useCallback, useState } from 'react';
import prettyBytes from 'pretty-bytes';
import { RenderDocumentIcon } from 'components/RenderDocumentIcon';
import { IDocumentRecord, ICommittee } from 'types';
import { transformDocumentRecordDataForm } from 'utils/transformDocumentRecordDataForm';
import { DocumentRecordFormFields } from 'components/DocumentRecords/DocumentRecordFormFields';
import { LoadingButton } from '@mui/lab';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { TextField } from 'components/temp/TextFieldTemp';
import { DateTimePicker } from 'components/temp/DateTimePickerTemp';
import { DialogFormTemp } from 'components/temp/DialogFormTemp';
import { Operations } from '@koopajs/app';
import { useDispatch } from 'react-redux';
import { fileUploadSnackbar } from 'utils/DocumentRecords/fileUploadSnackbar';
import { useSnackbar } from 'notistack';
import { useSingleDocumentInMemoryDropzone } from 'components/hooks/useSingleDocumentInMemoryDropzone';
import { useGateValue } from '@statsig/react-bindings';

export const AddDocumentToDocumentRecordDialog: React.FC = () => {
  const { t } = useLocale();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isDocumentUpToDateSwitchChecked, setIsDocumentUpToDateSwitchChecked] = useState(false);

  const handleIsDocumentUpToDateSwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDocumentUpToDateSwitchChecked(event.target.checked);
  }, []);

  const keyPrefix = 'Dialogs.AddDocumentToDocumentRecord';
  const fileUpload = useFileUpload();

  const addDocumentToDocumentRecordDialog = useComponentVisibility('addDocumentToDocumentRecordDialog');
  const { documentRecord } = addDocumentToDocumentRecordDialog.getContext() as {
    defaultValues: {
      title: string | undefined;
      committeeId: string;
      reviewInterval: string;
    };
    documentRecord?: IDocumentRecord;
  };

  const { resources: committees } = useResourceList<ICommittee>({ path: '/committees' });

  const committeesOptions = committees?.map((c) => ({ id: c.id as string, label: c.name as string })) || [];

  const {
    dropzone,
    isProcessing,
    blobUrl: dropzoneBlobUrl,
    file: dropzoneFile,
    resetFileInState
  } = useSingleDocumentInMemoryDropzone({
    isStoringFileInMemory: true
  });

  const onDialogClose = useCallback(() => {
    resetFileInState();
    setIsDocumentUpToDateSwitchChecked(false);
  }, []);

  const { createResource: createDocument } = useResourceCreate({
    path: `/document-records/${documentRecord?.id}/main-document`,
    customReducer: {
      path: { resourceType: '/document-records', resourceId: documentRecord?.id || '' },
      mapping: (previousObj: object, newObj: object) => {
        return newObj;
      }
    }
  });

  const { updateResource: updateDocumentRecord, errorMessage } = useResourceUpdate({
    path: '/document-records',
    id: documentRecord?.id || ''
  });

  const { workspace } = useWorkspaceShow();
  const search = useSearchParams();
  const dispatch = useDispatch();
  const resourcesFetchOperation = Operations.Resources.fetch(dispatch);

  const isPdf = dropzoneFile?.type === 'application/pdf';
  const isDocumentDownloadBlockedOnWorkspace = useGateValue('block_document_downloads');

  const handleSubmit = useCallback(
    async (data: object): Promise<boolean> => {
      const typedData = data as {
        committeeId: string;
        lastDocumentActiveSince: string;
        reviewInterval: string;
        lastDocumentTitle: string;
        toReviewAt: string;
      };
      let isSuccess = false;

      if (isDocumentUpToDateSwitchChecked) {
        const payload = {
          lastDocumentTitle: typedData.lastDocumentTitle,
          reviewInterval: null,
          lastDocumentActiveSince: documentRecord?.lastDocumentActiveSince,
          toReviewAt: typedData.toReviewAt ? new Date(typedData.toReviewAt + `T00:00:00Z`).toISOString() : ''
        };

        isSuccess = await updateDocumentRecord(payload);
      } else {
        if (!dropzoneFile) return false;

        const fileUri = await fileUpload.upload(dropzoneFile);
        const payload = transformDocumentRecordDataForm({ data, file: dropzoneFile, fileUri });

        isSuccess = await createDocument(payload);

        if (isSuccess) {
          fileUploadSnackbar({
            isSuccess,
            file: dropzoneFile,
            trackEvent: 'document-record-update',
            enqueueSnackbar,
            closeSnackbar,
            successMessage: t('common:snackbarFileUploadSuccess'),
            failMessage: t('common:snackbarFileUploadFail')
          });

          // refetch document history
          await resourcesFetchOperation(
            workspace?.id || '',
            `/document-records/${documentRecord?.id}/historical-documents`,
            { size: 10, ...search.searchParams }
          );
        }
      }

      if (isSuccess) onDialogClose();
      return isSuccess;
    },
    [documentRecord?.id, isDocumentUpToDateSwitchChecked, dropzoneFile]
  );

  const renderDocumentPanel = () => {
    if (!isDocumentUpToDateSwitchChecked && !dropzoneFile) {
      return (
        <Box
          {...dropzone.getRootProps()}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            //border: '2px dashed #8187A9',
            py: 1.75,
            px: 3,
            width: { xs: '100%', lg: '340px' },
            height: '216px'
          }}
          data-cy="add-document-to-document-record-dialog_add-document-input_container"
        >
          <input
            {...dropzone.getInputProps()}
            data-cy="add-document-to-document-record-dialog_add-document-input"
          />
          <LoadingButton
            onClick={dropzone.open}
            startIcon={<AttachFileIcon />}
            loading={isProcessing}
            loadingPosition="start"
            variant="outlined"
            data-cy="add-document-to-document-record-dialog_add-document-button"
          >
            <span>{t('common:labelAddDocument')}</span>
          </LoadingButton>
        </Box>
      );
    } else if (!isDocumentUpToDateSwitchChecked && dropzoneBlobUrl) {
      return (
        <Box sx={{ width: { xs: '100%', lg: '340px' }, height: '500px' }}>
          <iframe
            title="File Viewer"
            src={dropzoneBlobUrl}
            style={{ width: '100%', height: '100%' }}
            className="rr-block"
          />
        </Box>
      );
    }
  };

  return (
    <DialogFormTemp
      dialogKey="addDocumentToDocumentRecordDialog"
      onSubmit={handleSubmit}
      i18n={{ keyPrefix: keyPrefix + '.DialogForm' }}
      maxWidth="md"
      leftAction={
        <Button onClick={addDocumentToDocumentRecordDialog.setHidden}>{t('common:labelCancel')}</Button>
      }
      dialogProps={{ PaperProps: { 'data-cy': 'add-document-to-document-record-dialog' } }}
      onClose={onDialogClose}
      isSubmitDisabled={!dropzoneFile && !isDocumentUpToDateSwitchChecked}
    >
      {/* IS DOCUMENT UP TO DATE SWITCH */}
      <FormControlLabel
        control={
          <Switch
            checked={isDocumentUpToDateSwitchChecked}
            onChange={handleIsDocumentUpToDateSwitch}
            inputProps={{ 'aria-label': 'controlled' }}
            data-cy="add-document-to-document-record-dialog_up-to-date-switch"
          />
        }
        label={t(keyPrefix + '.isDocumentUpToDateSwitchLabel')}
      />

      <ErrorMessage error={errorMessage} />

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, gap: { xs: 1, lg: 3 }, mt: 2 }}>
        {/* ADD DOCUMENT BUTTON, PDF VIEWER */}
        {renderDocumentPanel()}

        <Box sx={{ flex: 1, minWidth: 0 }}>
          {/* NON PDF FILE INFO */}
          {dropzoneFile && dropzoneFile.type !== 'application/pdf' && (
            <Box sx={{ width: '100%', minWidth: '0px' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mb: 2
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden'
                  }}
                >
                  <RenderDocumentIcon fileType={dropzoneFile?.type} sx={{ mr: 1 }} />
                  <Typography
                    variant="body2"
                    display="inline"
                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    className="rr-mask"
                  >
                    {dropzoneFile?.name}
                  </Typography>
                </Box>
                <span>&#183;</span>
                <Typography variant="body2" sx={{ opacity: 0.6, flex: 'none' }}>
                  {dropzoneFile?.size && prettyBytes(dropzoneFile.size)}
                </Typography>
              </Box>
            </Box>
          )}
          {isDocumentUpToDateSwitchChecked ? (
            <>
              {/* FIELDS FOR OLD DOCUMENT */}
              <TextField
                name="lastDocumentTitle"
                i18n={{ keyPrefix: 'DocumentRecord.FormFields.FieldTitle' }}
                validationRules={{
                  maxLength: 100
                }}
                className="rr-block"
              />
              <DateTimePicker
                name="toReviewAt"
                i18n={{ keyPrefix: 'DocumentRecord.FormFields.FieldToReviewAt' }}
                type="date"
                inputProps={{ min: '2000-01-01', max: '2099-12-31' }}
                sx={{
                  '& .MuiInputBase-input': {
                    backgroundColor: 'white'
                  }
                }}
              />
            </>
          ) : (
            // FIELDS FOR NEW DOCUMENT
            <DocumentRecordFormFields
              committeesOptions={committeesOptions}
              committeeFieldState="hidden"
              categoryFieldState="hidden"
            />
          )}

          {dropzoneFile && !isPdf && isDocumentDownloadBlockedOnWorkspace && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {t('common:downloadBlockedUploadAlertLabel')}
            </Alert>
          )}
        </Box>
      </Box>
    </DialogFormTemp>
  );
};
