import { useLocale } from '@koopajs/react';
import { Typography, Box } from '@mui/material';
import { IMeeting, ITopic } from '../../../../../types';
import { PastMeetingsToApproveList } from '../../../../TopicCard/PastMinutesApprovalField/PastMeetingsToApproveList';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { formatTime } from 'utils/DateTime/formatTime';
import { RenderButtonLink } from 'components/RenderButtonLink';
import { formatDate } from 'utils/DateTime/formatDate';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useBoardMemberViewContext } from 'components/BoardMemberView/BoardMemberViewProvider';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { ResolutionOutcomeVotes } from 'components/ResolutionOutcome/ResolutionOutcomeVotes';
import { DocumentList } from '../../../../DocumentList';

interface IPastMinuteTopicProps {
  topic: ITopic;
  order: number;
  minutes?: IMeeting;
}

export const PastMinuteTopic: React.FC<IPastMinuteTopicProps> = (props) => {
  const { topic, order, minutes } = props;

  const boardMemberViewContext = useBoardMemberViewContext();
  const { meeting } = boardMemberViewContext;

  const keyPrefix = 'BoardMemberView.TopicCards.PastMinutesApproval';

  const { t, locale } = useLocale();

  const committeeType = useCommitteeName(minutes?.committeeId || '');
  const hasDocuments = topic?.minutesDocumentsIds && topic?.minutesDocumentsIds.length > 0;

  let adjournmentAt = minutes?.minutesEndedAt;
  if (topic.type === 'adjournment' && topic.version === 1) {
    if (topic.resolutionOutcome?.recordedAt && topic.resolutionOutcome?.outcome === 'approved') {
      adjournmentAt = topic.resolutionOutcome?.recordedAt;
    } else {
      adjournmentAt = '';
    }
  }

  if (topic.type === 'inCamera') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body1" sx={{ width: '80%', fontWeight: '700', whiteSpace: 'normal' }}>
          {order}. <>{topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}</>
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} data-cy="board-member-view-pma-topic">
      <Typography
        variant="body1"
        sx={{ width: '80%', fontWeight: '700', whiteSpace: 'normal' }}
        data-cy="board-member-view-pma-topic-title"
      >
        {order}. <>{topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}</>
      </Typography>

      {/* QUORUM */}

      {topic.type === 'quorum' && topic.quorumReachedAt && (
        <>
          {' '}
          <Typography variant="body1" data-cy="">
            {t(keyPrefix + '.meetingStarted', {
              time: formatTime({ isoString: minutes?.minutesStartedAt || '', locale })
            })}
          </Typography>
          <Typography data-cy="">
            {t(keyPrefix + '.quorumReached', {
              time: formatTime({ isoString: topic?.quorumReachedAt, locale })
            })}
          </Typography>
        </>
      )}

      {/* AGENDA ADOPTION */}
      {topic.type === 'agendaApproval' && (
        <Box sx={{ my: 1 }}>
          <RenderButtonLink
            to={`/meeting-annotate/${meeting?.id}/past-meetings/${minutes?.id}`}
            label={
              <Typography variant="body2" sx={{ whiteSpace: 'normal' }}>
                {formatDate({
                  isoString: minutes?.startDateTime as string,
                  locale,
                  format: 'DATE_FULL'
                })}
                {' | '}
                {committeeType}

                {' | '}

                {minutes?.title}
              </Typography>
            }
            icon={<CalendarTodayIcon fontSize="small" />}
            size="small"
          />
        </Box>
      )}

      {/* PAST MINUTES ADOPTION */}
      {topic.type === 'pastMinutesApproval' && (
        <PastMeetingsToApproveList
          meetingId={topic.meetingId}
          topicId={topic.id}
          topic={topic}
          showApprovedMeetings={true}
          hideLabel
        />
      )}

      {/* NOTES */}
      {[
        'quorum',
        'nominations',
        'agendaApproval',
        'pastMinutesApproval',
        'ceoReport',
        'information',
        'discussion',
        'resolution',
        'miscellaneous',
        'adjournment'
      ].includes(topic.type) &&
        topic.notes && (
          <RichTextReadOnly
            value={topic.notes}
            sxEditorContainer={{
              pt: 2,
              mb: 1.5
            }}
          />
        )}

      {/* ADJOURNMENT */}
      {topic.type === 'adjournment' && adjournmentAt && (
        <Typography data-cy="">
          {t('MeetingStateReview.adjournmentAt', {
            time: formatTime({
              isoString: adjournmentAt,
              locale
            })
          })}
        </Typography>
      )}

      {/* RESOLUTION OUTCOME */}
      {['nominations', 'agendaApproval', 'resolution', 'pastMinutesApproval', 'adjournment'].includes(
        topic.type
      ) &&
        topic?.resolutionOutcome?.outcome && (
          <Box sx={{ whiteSpace: 'normal' }}>
            <ResolutionOutcomeText topic={topic} />

            <ResolutionOutcomeVotes topic={topic} hideIcon />
          </Box>
        )}

      {hasDocuments && (
        <DocumentList path={`/meetings/${topic?.meetingId}/topics/${topic?.id}/minutes-documents`} />
      )}
    </Box>
  );
};
