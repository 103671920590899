import { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, IconButton, Button } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { useComponentVisibility, useLocale } from '@koopajs/react';
import { IDocument } from 'types';
import { DocumentAnnotation } from 'components/DocumentAnnotation';

//TODO: change the name of this component to be less specific
// this dialog is used by BMV, MeetingEdit and MeetingView
export const BoardMemberViewDocumentDialog: React.FC = () => {
  const keyPrefix = 'Dialogs.BoardMemberViewDocumentDialog';
  const { t } = useLocale();

  const dialog = useComponentVisibility('boardMemberViewDocumentDialog');

  const dialogContext = dialog.getContext() as {
    pdfDocuments?: IDocument[];
    documentId?: string;
  };

  const { pdfDocuments } = dialogContext;

  const [currentDocumentId, setCurrentDocumentId] = useState(dialogContext.documentId);

  const pdfDocumentIds = pdfDocuments?.map((document) => document.id);

  const currentDocumentIndex = currentDocumentId ? pdfDocumentIds?.indexOf(currentDocumentId) : undefined;

  const handleClickPreviousDocumentButton = useCallback(() => {
    if (pdfDocumentIds && currentDocumentIndex) {
      setCurrentDocumentId(pdfDocumentIds[currentDocumentIndex - 1]);
    }
  }, [JSON.stringify(pdfDocumentIds), currentDocumentIndex]);

  const handleClickNextDocumentButton = useCallback(() => {
    if (pdfDocumentIds && currentDocumentIndex !== undefined) {
      setCurrentDocumentId(pdfDocumentIds[currentDocumentIndex + 1]);
    }
  }, [JSON.stringify(pdfDocumentIds), currentDocumentIndex]);

  const isDocumentInList = pdfDocumentIds && currentDocumentIndex !== -1;

  const hasNextDocument = Boolean(isDocumentInList && currentDocumentIndex !== pdfDocumentIds.length - 1);

  const hasPreviousDocument = Boolean(isDocumentInList && currentDocumentIndex !== 0);

  useEffect(() => {
    setCurrentDocumentId(dialogContext.documentId);
  }, [dialogContext.documentId]);

  const handleClose = useCallback(async () => {
    dialog.setHidden();
  }, []);

  return (
    <Dialog
      open={dialog.isVisible}
      onClose={handleClose}
      PaperProps={{ 'data-cy': 'board-member-view-document-dialog' }}
      fullScreen
    >
      <DialogTitle data-cy="board-member-view-document-dialog_title">
        {t(keyPrefix + '.title')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey'
          }}
          data-cy="board-member-view-document-dialog_close-button"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0,
          lineHeight: 0 // this is getting rid of some random spacing being added
        }}
      >
        <>
          {currentDocumentId && (
            <Stack direction="row" height="100%">
              <Stack justifyContent="center" sx={{ mx: { xs: 1, md: 3 } }}>
                <IconButton
                  color="inherit"
                  onClick={handleClickPreviousDocumentButton}
                  sx={{
                    p: 0,
                    width: { xs: '24px', md: '48px' },
                    height: { xs: '24px', md: '48px' },
                    m: 0,
                    ...(hasPreviousDocument ? {} : { visibility: 'hidden' })
                  }}
                  aria-label={t(keyPrefix + '.previousDocument')}
                >
                  <KeyboardArrowLeftIcon sx={{ color: 'primary.main' }} />
                </IconButton>
              </Stack>

              <DocumentAnnotation
                allowAnnotate={true}
                documentId={currentDocumentId}
                sx={{ height: '100%' }}
                sxContainer={{ flex: 1 }}
                testId="document-annotation-iframe"
              />

              <Stack justifyContent="center" sx={{ mx: { xs: 1, md: 3 } }}>
                <IconButton
                  color="inherit"
                  onClick={handleClickNextDocumentButton}
                  sx={{
                    p: 0,
                    width: { xs: '24px', md: '48px' },
                    height: { xs: '24px', md: '48px' },
                    m: 0,
                    ...(hasNextDocument ? {} : { visibility: 'hidden' })
                  }}
                  aria-label={t(keyPrefix + '.nextDocument')}
                >
                  <KeyboardArrowRightIcon sx={{ color: 'primary.main' }} />
                </IconButton>
              </Stack>
            </Stack>
          )}
        </>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>{t('common:quit')}</Button>
      </DialogActions>
    </Dialog>
  );
};
