import { Box, Button, IconButton, Alert } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { TextField } from 'components/temp/TextFieldTemp';
import { DateTimePicker } from 'components/temp/DateTimePickerTemp';
import { ICommittee } from 'types';
import { SelectTemp } from 'components/temp/SelectTemp';
import { DocumentList } from 'components/DocumentList';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import { useGateValue } from '@statsig/react-bindings';

interface IUploadedMeetingCardProps {
  committees: ICommittee[];
  uploadedMeetingId?: string;
  handleDeleteUploadedMeeting?: () => void;
  isProcessing: boolean;
  isPdf: boolean;
}
export const UploadedMeetingCardEditable: React.FC<IUploadedMeetingCardProps> = (props) => {
  const { committees, uploadedMeetingId, handleDeleteUploadedMeeting, isProcessing, isPdf } = props;
  const { t } = useLocale();
  const keyPrefix = 'UploadedMeetings.UploadedMeetingCard.Form';

  const isDocumentDownloadBlockedOnWorkspace = useGateValue('block_document_downloads');

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
      data-cy="uploaded-meeting-add-card-editable"
    >
      <Box sx={{ display: 'flex', gap: { xs: 0, md: 3 }, flexDirection: { xs: 'column', md: 'row' } }}>
        <DateTimePicker
          name="startDateTime"
          i18n={{ keyPrefix: keyPrefix + '.FieldDate' }}
          type="date"
          inputProps={{ min: '2000-01-01', max: '2099-12-31' }}
          validationRules={{
            pattern: /^[2]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/g
          }}
          sx={{
            '& .MuiInputBase-input': {
              backgroundColor: 'white'
            }
          }}
        />
        <SelectTemp
          name="committeeId"
          i18n={{ keyPrefix: keyPrefix + '.FieldCommitteeType' }}
          options={committees
            ?.sort((committeeA, committeeB) => {
              return committeeA.name > committeeB.name ? 1 : -1;
            })
            .map((committee) => ({
              id: committee.id,
              label: committee.name
            }))}
        />
      </Box>
      <TextField
        name="title"
        validationRules={{ maxLength: 100 }}
        i18n={{ keyPrefix: keyPrefix + '.FieldTitle' }}
      />

      {!isPdf && isDocumentDownloadBlockedOnWorkspace && (
        <Alert severity="error">{t('common:downloadBlockedUploadAlertLabel')}</Alert>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {handleDeleteUploadedMeeting && (
          <IconButton
            onClick={handleDeleteUploadedMeeting}
            data-cy="uploaded-meeting-add-card-editable_delete-button"
            disabled={isProcessing}
            sx={{ mr: 2 }}
          >
            <DeleteOutlineIcon fontSize="small" />
          </IconButton>
        )}

        <Button
          type="submit"
          variant="contained"
          sx={{ alignSelf: 'flex-end' }}
          disabled={isProcessing}
          data-cy="uploaded-meeting-add-card-editable_save-button"
        >
          {t('common:labelSave')}
        </Button>
      </Box>
      {uploadedMeetingId && (
        <DocumentList
          path={`/uploaded-meetings/${uploadedMeetingId}/documents`}
          isEditable={uploadedMeetingId ? true : false}
          isAccordion={true}
          accordionOptions={{ documentsVisible: 2 }}
          sxDocument={{ display: 'flex' }}
        />
      )}
    </Box>
  );
};
