import { Box, Button, ButtonProps, Typography } from '@mui/material';
import { RenderDocumentIcon } from 'components/RenderDocumentIcon';
import { IDocument } from 'types';
import { SystemStyleObject } from '@mui/system';
import { useIsDocumentDownloadBlocked } from 'components/hooks/useIsDocumentDownloadBlocked';
import { useLocale } from '@koopajs/react';

interface IBoardMemberViewTopicCardDocumentButton {
  document: IDocument;
  sxContainer?: SystemStyleObject;
  buttonActionProps?: ButtonProps;
  disableScrollToDocument?: boolean;
}

export const BoardMemberViewTopicCardDocumentButton: React.FC<IBoardMemberViewTopicCardDocumentButton> = (
  props
) => {
  const { document, sxContainer, buttonActionProps, disableScrollToDocument } = props;

  const isDocumentDownloadBlocked = useIsDocumentDownloadBlocked();

  const additionalButtonProps = buttonActionProps
    ? buttonActionProps
    : {
        target: '_blank',
        rel: 'noopener',
        href: `/documents/${document.id}`,
        component: 'a'
      };

  const isUrl = document?.attachedFile?.format === 'url';
  const isPdf = document?.attachedFile?.format === 'application/pdf';
  const isNotPdfOrUrl = !isUrl && !isPdf;

  const { t } = useLocale();

  return (
    <Box
      // ref={targetRef}
      sx={{
        ...sxContainer
      }}
      data-cy="board-member-view_document-view-button-container"
      // this is needed for scroll into view from email
      id={disableScrollToDocument ? undefined : document.id}
      data-topic-id={document.topicId}
    >
      <Button
        sx={{
          textTransform: 'none',
          color: 'link.main',
          p: 0.2,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%'
        }}
        data-cy="board-member-view_document-view-button"
        disabled={isDocumentDownloadBlocked && isNotPdfOrUrl}
        {...additionalButtonProps}
      >
        <RenderDocumentIcon fileType={document.attachedFile.format} sx={{ opacity: 1 }} />

        <Box
          sx={{
            ml: 1,
            maxWidth: '500px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '16px'
          }}
        >
          {document?.attachedFile?.name || document?.attachedFile?.uri}
        </Box>
      </Button>

      {isDocumentDownloadBlocked && isNotPdfOrUrl && (
        <Typography variant="caption" sx={{ display: 'block', opacity: 0.6, lineHeight: 1 }}>
          {t('common:downloadBlockedDocumentMessage')}
        </Typography>
      )}
    </Box>
  );
};
