import { Box, Typography, IconButton, List, ListItem, ListItemText, Paper } from '@mui/material';
import {
  useLocale,
  useResourceCreate,
  useFileUpload,
  useResourceShow,
  useResourceUpdate,
  useResourceDelete,
  usePermissions,
  useAppContext
} from '@koopajs/react';
import { RenderDocumentIcon } from 'components/RenderDocumentIcon';
import prettyBytes from 'pretty-bytes';
import { FormControllerTemp } from 'components/temp/FormControllerTemp';
import { ICommittee, IDocument, IUploadedResolution } from 'types';
import { useState, useCallback } from 'react';
import { UploadedResolutionCardEditable } from './UploadedResolutionCardEditable';
import { Edit as EditIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { formatDate } from 'utils/DateTime/formatDate';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { DateTime } from 'luxon';
import { ErrorMessageTemp } from 'components/temp/ErrorMessageTemp';
import { DocumentList } from 'components/DocumentList';
import { useAppSnackbar } from 'components/hooks/useAppSnackbar';

export interface IFile {
  id?: string;
  blobUrl?: string;
  file: File;
}

interface IUploadedResolutionCardProps {
  file: IFile;
  committees: ICommittee[];
  index: number;
}

export const UploadedResolutionCard: React.FC<IUploadedResolutionCardProps> = (props) => {
  const {
    file: { file, blobUrl, id: fileId },
    committees,
    index
  } = props;
  const { t, locale } = useLocale();

  const titleWithoutFileFormat = file.name ? file?.name.replace(/\.[^/.]+$/, '') : undefined;

  const keyPrefix = 'UploadedResolutions.UploadedResolutionCard';

  const { getComponentContext, setComponentContext } = useAppContext();
  const { files } = getComponentContext('uploadedResolutions') as { files: IFile[] };

  const [isInEditMode, setIsInEditMode] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  const { triggerSnackbar } = useAppSnackbar();

  const fileUpload = useFileUpload();

  const { resource: uploadedResolution } = useResourceShow<IUploadedResolution>({
    path: '/uploaded-resolutions',
    id: fileId
  });

  const { resource: mainDocument } = useResourceShow<IDocument>({
    path: '/documents',
    id: uploadedResolution?.mainDocumentId
  });

  const { createResource: createUploadedResolution, errorMessage } = useResourceCreate({
    path: '/uploaded-resolutions'
  });

  const {
    updateResource: updatedUploadedResolution,
    errorMessage: errorMessageUpdate,
    isProcessing: isUpdateUploadedResolutionProcessing
  } = useResourceUpdate({
    path: '/uploaded-resolutions',
    id: uploadedResolution?.id as string
  });

  const {
    deleteResource: deleteUploadedResolution,
    errorMessage: errorMessageDelete,
    isProcessing: isDeleteUploadedResolutionProcessing
  } = useResourceDelete({
    path: '/uploaded-resolutions',
    id: uploadedResolution?.id as string
  });

  const committeeName = useCommitteeName(uploadedResolution?.committeeId as string);

  const isPdf = Boolean(
    mainDocument?.attachedFile?.format === 'application/pdf' || file.type === 'application/pdf'
  );
  const isImage = Boolean(
    mainDocument?.attachedFile?.format.startsWith('image') || file.type.startsWith('image')
  );
  const isPdfOrImage = isPdf || isImage;

  const handleSetToEditMode = useCallback(() => {
    return () => setIsInEditMode(true);
  }, []);

  // SAVE UPLOADED RESOLUTION
  const handleSubmitCreate = useCallback(
    async (data: object) => {
      const typedData = data as {
        resolutionOutcome: { recordedAt: string; outcome: string };
      };

      setIsProcessing(true);

      const fileUri = await fileUpload.upload(file);

      const payload = {
        ...typedData,
        resolutionOutcome: {
          recordedAt: new Date(typedData.resolutionOutcome.recordedAt + `T00:00:00Z`).toISOString(),
          outcome: typedData.resolutionOutcome.outcome
        },

        attachedFile: {
          uri: fileUri,
          format: file.type,
          name: file.name,
          sizeBytes: file.size
        }
      };

      let uploadedDocumentId = '';
      const isSuccess = await createUploadedResolution(payload, (id) => {
        uploadedDocumentId = id;
      });
      setIsProcessing(false);

      if (isSuccess) {
        setIsInEditMode(false);

        const updatedFiles = files.map((f, i) => {
          if (f.file.lastModified === file.lastModified && f.file.name === file.name && i === index) {
            const updatedFile = Object.assign(f, { id: uploadedDocumentId });

            return updatedFile;
          } else {
            return f;
          }
        });

        setComponentContext('uploadedResolutions', { files: updatedFiles });
      }

      return isSuccess;
    },
    [file, files]
  );

  // UPDATE UPLOADED RESOLUTION
  const handleSubmitUpdate = useCallback(
    async (data: object) => {
      const typedData = data as {
        resolutionOutcome: { recordedAt: string; outcome: string };
      };

      const payload = {
        ...typedData,
        resolutionOutcome: {
          recordedAt: new Date(typedData.resolutionOutcome.recordedAt + `T00:00:00Z`).toISOString(),
          outcome: typedData.resolutionOutcome.outcome
        }
      };

      const isSuccess = await updatedUploadedResolution(payload);

      if (isSuccess) {
        setIsInEditMode(false);
      }

      return isSuccess;
    },
    [uploadedResolution?.id]
  );

  //DELETE UPLOADED RESOLUTION
  const handleDeleteUploadedResolution = useCallback(async () => {
    if (uploadedResolution?.id) {
      const isSuccess = await deleteUploadedResolution();
      if (isSuccess) {
        const updatedFiles = files.filter((f, i) => f.id !== uploadedResolution?.id);

        setComponentContext('uploadedResolutions', { files: updatedFiles });

        triggerSnackbar({
          snackbarText: t(keyPrefix + '.snackbarDeleteSuccess'),
          variant: 'success'
        });
      } else {
        triggerSnackbar({
          snackbarText: t(keyPrefix + '.snackbarDeleteFail'),
          variant: 'error'
        });
      }
    } else {
      const updatedFiles = files.filter((f, i) => i !== index && f.file.name !== file.name);
      setComponentContext('uploadedResolutions', { files: updatedFiles });

      triggerSnackbar({
        snackbarText: t(keyPrefix + '.snackbarDeleteSuccess'),
        variant: 'success'
      });
    }
  }, [deleteUploadedResolution, uploadedResolution?.id, JSON.stringify(files)]);

  return (
    <Paper
      variant="outlined"
      sx={{
        my: 2,
        padding: '24px'
      }}
      data-cy="uploaded-resolution-card"
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3
          }}
        >
          {/* PDF VIEWER */}

          {isPdfOrImage && (mainDocument?.attachedFileUri || blobUrl) && (
            <Box sx={{ width: { xs: '100%', md: '40%' } }}>
              <iframe
                style={{
                  border: '1px solid gray',
                  backgroundColor: 'rgba(0, 0, 0, 0.11)',
                  width: '100%',
                  height: '100%'
                }}
                className="rr-block"
                src={mainDocument?.attachedFileUri || blobUrl}
                data-cy=""
              />
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', md: isPdfOrImage || blobUrl ? '60%' : '100%' },
              gap: 3
            }}
          >
            <ErrorMessageTemp error={errorMessage || errorMessageUpdate || errorMessageDelete} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  minWidth: '0px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden'
                  }}
                >
                  {(mainDocument?.attachedFile?.format || file?.type) && (
                    <RenderDocumentIcon
                      fileType={mainDocument?.attachedFile?.format || file.type}
                      sx={{ mr: 1 }}
                    />
                  )}
                  <Typography
                    variant="body2"
                    display="inline"
                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    data-cy="uploaded-resolution-card_file-name"
                    className="rr-mask"
                  >
                    {mainDocument?.attachedFile?.name || file?.name}
                  </Typography>
                </Box>

                <span>&#183;</span>
                <Typography variant="body2" sx={{ opacity: 0.6, flex: 'none' }}>
                  {(mainDocument?.attachedFile?.sizeBytes || file?.size) &&
                    prettyBytes(mainDocument?.attachedFile.sizeBytes || file.size)}
                </Typography>
              </Box>
              {!isInEditMode && isUserWorkspaceOwner && (
                <IconButton
                  data-cy="uploaded-resolution-card_edit-button"
                  sx={{ alignSelf: 'flex-start', ml: 2 }}
                  onClick={handleSetToEditMode()}
                >
                  <EditIcon />
                </IconButton>
              )}
            </Box>
            {isInEditMode && isUserWorkspaceOwner ? (
              <FormControllerTemp
                onSubmit={uploadedResolution?.id ? handleSubmitUpdate : handleSubmitCreate}
                defaultValues={{
                  title: uploadedResolution?.title || titleWithoutFileFormat,
                  resolutionNumber: uploadedResolution?.resolutionNumber,
                  resolutionOutcome: {
                    outcome: uploadedResolution?.resolutionOutcome?.outcome || 'approved',
                    recordedAt:
                      uploadedResolution?.resolutionOutcome?.recordedAt &&
                      DateTime.fromISO(uploadedResolution?.resolutionOutcome?.recordedAt, {
                        zone: 'utc'
                      }).toFormat('yyyy-MM-dd')
                  },

                  committeeId: uploadedResolution?.committeeId
                }}
              >
                <UploadedResolutionCardEditable
                  committees={committees}
                  uploadedResolutionId={uploadedResolution?.id}
                  handleDeleteUploadedResolution={handleDeleteUploadedResolution}
                  isProcessing={isProcessing || isUpdateUploadedResolutionProcessing}
                  isPdf={isPdf}
                />
              </FormControllerTemp>
            ) : (
              <>
                <List>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                      {uploadedResolution && uploadedResolution.resolutionNumber && (
                        <ListItem
                          alignItems="flex-start"
                          sx={{ px: 0, minWidth: 0 }}
                          data-cy="uploaded-resolution-card_resolutionNumber-li"
                        >
                          <ListItemText
                            primary={t(keyPrefix + '.resolutionNumber')}
                            primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                            secondary={
                              <Typography
                                sx={{
                                  display: 'inline',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '100%',
                                  minWidth: '0px',
                                  whiteSpace: 'nowrap'
                                }}
                                component="span"
                                variant="body1"
                                color="text.primary"
                              >
                                {`#${uploadedResolution?.resolutionNumber}`}
                              </Typography>
                            }
                            sx={{
                              px: 0,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          />
                        </ListItem>
                      )}
                      <ListItem
                        alignItems="flex-start"
                        sx={{ px: 0, minWidth: 0 }}
                        data-cy="uploaded-resolution-card_title-li"
                      >
                        <ListItemText
                          primary={t(keyPrefix + '.title')}
                          primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                          secondary={
                            <Typography
                              sx={{
                                display: 'inline',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '100%',
                                minWidth: '0px',
                                whiteSpace: 'nowrap'
                              }}
                              component="span"
                              variant="body1"
                              color="text.primary"
                            >
                              {uploadedResolution && uploadedResolution.title}
                            </Typography>
                          }
                          sx={{
                            px: 0,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        />
                      </ListItem>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                      <ListItem
                        alignItems="flex-start"
                        sx={{ px: 0 }}
                        data-cy="uploaded-resolution-card_committee-li"
                      >
                        <ListItemText
                          primary={t(keyPrefix + '.group')}
                          primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                          secondary={
                            <Typography
                              sx={{
                                display: 'inline'
                              }}
                              component="span"
                              variant="body1"
                              color="text.primary"
                            >
                              {uploadedResolution && committeeName}
                            </Typography>
                          }
                          sx={{ px: 0 }}
                        />
                      </ListItem>
                      <ListItem
                        alignItems="flex-start"
                        sx={{ px: 0 }}
                        data-cy="uploaded-resolution-card_resolutionOutcome-li"
                      >
                        <ListItemText
                          primary={t(keyPrefix + '.resolutionOutcome')}
                          primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                          secondary={
                            <Typography
                              sx={{
                                display: 'inline'
                              }}
                              component="span"
                              variant="body1"
                              color="text.primary"
                            >
                              {uploadedResolution &&
                                t(
                                  `common:TopicModel.resolutionOutcome.outcome.${uploadedResolution.resolutionOutcome?.outcome}`
                                )}
                            </Typography>
                          }
                          sx={{ px: 0 }}
                        />
                      </ListItem>{' '}
                    </Box>
                    <ListItem
                      alignItems="flex-start"
                      sx={{ px: 0 }}
                      data-cy="uploaded-resolution-card_date-li"
                    >
                      <ListItemText
                        primary={t(keyPrefix + '.adoptionDate')}
                        primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                        secondary={
                          <Typography
                            sx={{
                              display: 'inline'
                            }}
                            component="span"
                            variant="body1"
                            color="text.primary"
                          >
                            {uploadedResolution &&
                              formatDate({
                                isoString: uploadedResolution?.resolutionOutcome.recordedAt as string,
                                locale,
                                format: 'DATE_FULL',
                                isUTC: true
                              })}
                          </Typography>
                        }
                        sx={{ px: 0 }}
                      />
                    </ListItem>
                  </Box>
                </List>
              </>
            )}
            {uploadedResolution?.id && !isInEditMode && (
              <Box
                sx={{
                  display: 'flex',
                  gap: { xs: 2, md: 0 },
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: {
                    xs: 'flex-start',
                    md: 'space-between'
                  }
                }}
              >
                <Box sx={{ minWidth: 0 }}>
                  <DocumentList
                    path={`/uploaded-resolutions/${uploadedResolution?.id}/documents`}
                    isAccordion={true}
                    accordionOptions={{ documentsVisible: 2 }}
                    isEditable={isUserWorkspaceOwner && Boolean(uploadedResolution?.id)}
                    sxDocument={{ display: 'flex' }}
                  />
                </Box>

                <Box
                  sx={{ display: 'flex', py: '6px', alignSelf: { md: 'flex-end' }, minWidth: 'fit-content' }}
                >
                  <CheckCircleIcon color="success" />
                  <Typography variant="body1" sx={{ opacity: '0.6', ml: 1 }}>
                    {t(keyPrefix + '.addedToBooks')}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
