import { IDocument } from 'types';
import { useResourceList } from '@koopajs/react';

export const getMeetingPdfDocuments = (meetingId?: string): IDocument[] => {
  const { resources: documents } = useResourceList<IDocument>({
    path: `/meetings/${meetingId}/documents`
  });

  return documents.filter((doc) => doc.attachedFile.format === 'application/pdf');
};
