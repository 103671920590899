import { IDocument } from 'types';
import { useCallback } from 'react';
import { Button, IconButton, Tooltip, Box, Typography } from '@mui/material';
import {
  DeleteOutline as DeleteOutlineIcon,
  Close as CloseIcon,
  OpenInNew as OpenInNewIcon
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useLocale, useResourceDelete, useComponentVisibility } from '@koopajs/react';
import { TrackEvent } from '@koopajs/app';
import { SystemStyleObject } from '@mui/system';
import { RenderDocumentIcon } from 'components/RenderDocumentIcon';
import { useIsDocumentDownloadBlocked } from 'components/hooks/useIsDocumentDownloadBlocked';
interface IDocumentProps {
  document: IDocument;
  isDeletable?: boolean;
  isViewPrint?: boolean;
  path: string;
  setIsProcessing?: (isProcessing: boolean) => void;
  isProcessing: boolean;
  sx?: SystemStyleObject;
  meetingPdfDocuments?: IDocument[];
  isAnnotatable?: boolean;
}

export const Document: React.FC<IDocumentProps> = (props) => {
  const {
    isDeletable,
    document,
    isViewPrint,
    path,
    setIsProcessing,
    isProcessing: isProcessingOtherDocuments,
    sx,
    meetingPdfDocuments,
    isAnnotatable
  } = props;

  const isUrl = document?.attachedFile?.format === 'url';
  const isPdf = document?.attachedFile?.format === 'application/pdf';
  const isNotPdfOrUrl = !isUrl && !isPdf;

  const documentViewDialog = useComponentVisibility('documentPdfDialogView');
  const documentAnnotateDialog = useComponentVisibility('boardMemberViewDocumentDialog');

  const keyPrefix = 'Components.DocumentList';
  const { t } = useLocale();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const deleteDocument = useResourceDelete({
    path: path,
    id: document.id
  });
  // allow annotation of documents only in Edit Meeting and View Meeting
  const handleOpenDialog = useCallback(() => {
    if ((meetingPdfDocuments && meetingPdfDocuments.length > 0) || isAnnotatable) {
      documentAnnotateDialog.setVisibleWithContext({
        documentId: document.id,
        pdfDocuments: meetingPdfDocuments
      });
    } else {
      documentViewDialog.setVisibleWithContext({ documentId: document.id });
    }
  }, [document.id, JSON.stringify(meetingPdfDocuments), isAnnotatable]);

  const handleCloseSnackbar = useCallback((key: any) => {
    return () => {
      closeSnackbar(key);
    };
  }, []);

  const handleDeleteDocument = useCallback(async () => {
    if (setIsProcessing) setIsProcessing(true);

    const res = await deleteDocument.deleteResource();

    if (res) {
      enqueueSnackbar(t(keyPrefix + '.snackbarDeleteSuccess'), {
        variant: 'success',
        action: (key: any) => (
          <IconButton size="small" onClick={handleCloseSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
    } else {
      enqueueSnackbar(t(keyPrefix + '.snackbarDeleteFail'), {
        variant: 'error',
        action: (key: any) => (
          <IconButton size="small" onClick={handleCloseSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });

      TrackEvent('document-delete-fail');
    }
    if (setIsProcessing) setIsProcessing(false);
  }, []);

  const isDocumentDownloadBlocked = useIsDocumentDownloadBlocked();

  return (
    <>
      <Box sx={{ maxWidth: '100%', ...sx }} data-cy="document-view-button-container">
        <Tooltip title={t('common:labelView')}>
          <Button
            sx={{
              textTransform: 'none',
              color: isViewPrint ? 'inherit' : 'link.main',
              p: 0.2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%'
            }}
            component="a"
            data-cy="document-view-button"
            {...(isPdf && { onClick: handleOpenDialog })} // Open PDF in dialog
            {...(!isPdf && {
              href: isUrl ? document.attachedFile.uri : `/documents/${document.id}`,
              target: '_blank',
              rel: 'noopener'
            })} // open link or non PDF document in new tab
            disabled={isNotPdfOrUrl && isDocumentDownloadBlocked}
          >
            {isUrl ? (
              <OpenInNewIcon />
            ) : (
              <RenderDocumentIcon fileType={document.attachedFile.format} sx={{ opacity: 1 }} />
            )}

            <Box
              sx={{
                ml: 1,
                maxWidth: '500px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '16px'
              }}
            >
              {document?.attachedFile?.name || document?.attachedFile?.uri}
            </Box>
          </Button>
        </Tooltip>
        {isDeletable && (
          <Tooltip title={t('common:labelDelete')}>
            <IconButton
              onClick={handleDeleteDocument}
              data-cy="document-delete-button"
              disabled={isProcessingOtherDocuments}
            >
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {isDocumentDownloadBlocked && isNotPdfOrUrl && (
        <Typography variant="caption" sx={{ display: 'block', opacity: 0.6, lineHeight: 1 }}>
          {t('common:downloadBlockedDocumentMessage')}
        </Typography>
      )}
    </>
  );
};
