import { useCallback, useEffect, useState } from 'react';
import { IDocument, IUploadedResolution } from 'types';
import { Box, Typography, Paper, Button, IconButton, Stack } from '@mui/material';
import { Dialog, ErrorMessage, Loading } from '@koopajs/mui';
import { DocumentList } from '../DocumentList';
import { useHistory } from 'react-router-dom';
import { useLocale, usePermissions, useResourceShow } from '@koopajs/react';
import { EventsTimeline } from 'components/EventsTimeline';
import DownloadIcon from '@mui/icons-material/Download';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import DownloadingIcon from '@mui/icons-material/Downloading';
import EditIcon from '@mui/icons-material/Edit';
import { RenderDocumentIcon } from 'components/RenderDocumentIcon';
import { formatDate } from 'utils/DateTime/formatDate';
import prettyBytes from 'pretty-bytes';
import { UploadedResolutionDialogEditForm } from 'components/Resolutions/UploadedResolutions/UploadedResolutionDialogEditForm';
import { DeleteUploadedResolution } from 'components/Modals/DeleteUploadedResolution';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { useAppSnackbar } from 'components/hooks/useAppSnackbar';
import { DocumentAnnotation } from 'components/DocumentAnnotation';
import { theme } from 'components/Core/theme';
import { useIsDocumentDownloadBlocked } from 'components/hooks/useIsDocumentDownloadBlocked';

interface IViewUploadedResolutionProps {
  uploadedResolutionId?: string;
}

export const ViewUploadedResolution: React.FC<IViewUploadedResolutionProps> = (props) => {
  const { uploadedResolutionId } = props;

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isDeleteUploadedResolutionOpen, setIsDeleteUploadedResolutionOpen] = useState(false);

  const history = useHistory();

  const {
    resource: uploadedResolution,
    isProcessing,
    errorMessage
  } = useResourceShow<IUploadedResolution>({
    path: '/uploaded-resolutions',
    id: uploadedResolutionId
  });

  const {
    resource: mainDocument,
    isProcessing: isDocumentProcessing,
    errorMessage: documentErrorMessage
  } = useResourceShow<IDocument>({
    path: '/documents',
    id: uploadedResolution?.mainDocumentId
  });

  const keyPrefix = 'Dialogs.ViewUploadedResolution';
  const { t, locale } = useLocale();

  const hasDocuments = Boolean(uploadedResolution?.documentsIds?.length);

  const extractedResolutionErrorMessage = extractErrorMessage(errorMessage);
  const extractedDocumentErrorMessage = extractErrorMessage(documentErrorMessage);
  const committeeType = useCommitteeName(uploadedResolution?.committeeId || '');

  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  const timelineEvents = [
    { title: t('Components.EventsTimeline.updatedOn'), timestamp: uploadedResolution?.$updatedAt },
    {
      title: t('Components.EventsTimeline.createdOn'),
      timestamp: uploadedResolution?.$createdAt
    }
  ];

  const isDocumentPdf = mainDocument?.attachedFile?.format === 'application/pdf';
  const isDocumentImage = mainDocument?.attachedFile?.format.startsWith('image');

  const handleOnCloseDialog = useCallback(() => {
    history.push({ pathname: `/resolutions`, search: history.location?.search });
    setIsInEditMode(false);
  }, [history.location?.search]);

  const { triggerSnackbar } = useAppSnackbar();

  useEffect(() => {
    if (errorMessage) {
      triggerSnackbar({
        snackbarText: extractedResolutionErrorMessage,
        variant: 'error'
      });

      handleOnCloseDialog();
    }
  }, [errorMessage, uploadedResolutionId]);

  const handleSetToEditMode = useCallback(() => {
    return () => setIsInEditMode(true);
  }, []);

  const handleOpenDeleteModal = useCallback(() => {
    setIsDeleteUploadedResolutionOpen(true);
  }, []);
  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteUploadedResolutionOpen(false);
  }, []);

  const isDocumentDownloadBlocked = useIsDocumentDownloadBlocked();

  return (
    <Dialog.View
      isOpen={Boolean(uploadedResolutionId && uploadedResolution)}
      onClose={handleOnCloseDialog}
      isCloseVisible={true}
      i18n={{ keyPrefix: keyPrefix + '.DialogView' }}
      footerActions={
        !isDocumentDownloadBlocked && mainDocument?.attachedFile && !isDocumentProcessing ? (
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            href={`/documents/${mainDocument?.id}`}
            target="_blank"
            rel="noopener"
          >
            {t(keyPrefix + '.downloadResolutionButtonLabel')}
          </Button>
        ) : undefined
      }
      dialogProps={{ className: 'rr-block', PaperProps: { 'data-cy': 'uploaded-resolution-dialog' } }}
    >
      {isDocumentProcessing ? (
        <Stack alignItems="center">
          <Loading sx={{ backgroundColor: 'transparent', position: 'static' }} />
        </Stack>
      ) : documentErrorMessage ? (
        <ErrorMessage error={extractedDocumentErrorMessage} />
      ) : isDocumentPdf && mainDocument?.id ? (
        <Box>
          <DocumentAnnotation
            documentId={mainDocument.id}
            sx={{
              height: 'calc(100vh - 64px - 64px - 52.5px)'
            }}
          />
        </Box>
      ) : isDocumentImage && mainDocument?.id && !isDocumentDownloadBlocked ? (
        <iframe
          style={{
            border: `1px solid ${theme.palette.customGrey?.light}`,
            backgroundColor: 'rgba(0, 0, 0, 0.11)',
            width: '100%',
            height: 'calc(100vh - 64px - 64px - 52.5px)'
          }}
          className="rr-block"
          src={mainDocument?.attachedFileUri}
        />
      ) : (
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: '24px',
            gap: 2
          }}
          variant="outlined"
        >
          {isDocumentDownloadBlocked ? (
            <Typography variant="body2" sx={{ opacity: 0.6 }}>
              {t('common:downloadBlockedDocumentMessage')}
            </Typography>
          ) : (
            <>
              <DownloadingIcon />
              <Typography align="center" variant="h5">
                {t(keyPrefix + '.downloadDocumentTitle')}
              </Typography>

              <Button
                variant="outlined"
                startIcon={<DownloadIcon />}
                href={`/documents/${mainDocument?.id}`}
                target="_blank"
                rel="noopener"
              >
                {t(keyPrefix + '.downloadResolutionButtonLabel')}
              </Button>
            </>
          )}
        </Paper>
      )}
      {isProcessing && !isDocumentProcessing ? ( // only when not documents processing to avoid double spinner
        <Stack alignItems="center" sx={{ mt: 3 }}>
          <Loading sx={{ backgroundColor: 'transparent', position: 'static' }} />
        </Stack>
      ) : uploadedResolution ? (
        <Box sx={{ mb: 6.25, display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
          {isInEditMode && mainDocument ? (
            <UploadedResolutionDialogEditForm
              uploadedResolution={uploadedResolution}
              mainDocument={mainDocument}
              setIsInEditMode={setIsInEditMode}
            />
          ) : (
            <Paper
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 3,
                gap: 2
              }}
              data-cy="uploaded-resolution-dialog_info-card"
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                  variant="h6"
                  sx={{ display: 'flex', gap: 1, flexDirection: { xs: 'column', md: 'row' } }}
                >
                  <Box component="span" data-cy="uploaded-resolution-dialog_info-card_resolution-number">
                    {t(keyPrefix + '.resolutionLabel')}
                    {uploadedResolution.resolutionNumber && ' #' + uploadedResolution.resolutionNumber}
                  </Box>
                  <Box component="span" sx={{ display: { xs: 'none', md: 'flex' } }}>
                    |
                  </Box>
                  <Box component="span" data-cy="uploaded-resolution-dialog_info-card_committee">
                    {committeeType}
                  </Box>
                </Typography>
                {!isInEditMode && isUserWorkspaceOwner && mainDocument && (
                  <IconButton
                    sx={{ alignSelf: 'flex-start', ml: 2 }}
                    onClick={handleSetToEditMode()}
                    data-cy="uploaded-resolution-dialog_edit-button"
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>

              <Typography
                variant="body1"
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
                data-cy="uploaded-resolution-dialog_info-card_title"
              >
                {uploadedResolution.title}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  opacity: '0.6',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  overflow: 'hidden'
                }}
              >
                <Box>
                  <Typography variant="body2" data-cy="uploaded-resolution-dialog_info-card_outcome-date">
                    {t(
                      `common:TopicModel.resolutionOutcome.outcome.${uploadedResolution.resolutionOutcome.outcome}`
                    )}

                    {uploadedResolution.resolutionOutcome.recordedAt && (
                      <>
                        <span> {t('common:on')} </span>
                        {formatDate({
                          isoString: uploadedResolution.resolutionOutcome.recordedAt,
                          locale,
                          isUTC: true
                        })}
                      </>
                    )}
                  </Typography>
                </Box>
                {mainDocument?.attachedFile && (
                  <Stack direction="row" gap={1} sx={{ maxWidth: '100%' }}>
                    <RenderDocumentIcon fileType={mainDocument?.attachedFile?.format} />
                    <Typography
                      variant="body2"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                        minWidth: 0
                      }}
                    >
                      {mainDocument?.attachedFile.name}
                    </Typography>
                    <Box component="span" sx={{ display: { xs: 'none', md: 'flex' } }}>
                      &#183;
                    </Box>
                    <Typography variant="body2">
                      {prettyBytes(mainDocument?.attachedFile.sizeBytes)}
                    </Typography>
                  </Stack>
                )}
              </Box>
              {hasDocuments && (
                <DocumentList
                  path={`/uploaded-resolutions/${uploadedResolutionId}/documents`}
                  isAccordion={true}
                  accordionOptions={{
                    defaultExpanded: true,
                    label: t(keyPrefix + '.documentationLabel'),
                    documentsVisible: 2
                  }}
                />
              )}
            </Paper>
          )}
        </Box>
      ) : null}

      <EventsTimeline timelineEvents={timelineEvents} />

      {isUserWorkspaceOwner && uploadedResolution && (
        <>
          <DeleteUploadedResolution
            uploadedResolution={uploadedResolution}
            isOpen={isDeleteUploadedResolutionOpen}
            onClose={handleCloseDeleteModal}
          />
          <Button
            onClick={handleOpenDeleteModal}
            color="error"
            sx={{ display: { xs: 'none', sm: 'block' } }}
            data-cy="uploaded-resolution-dialog_delete-button"
          >
            {t(keyPrefix + '.labelDeleteUploadedResolution')}
          </Button>
        </>
      )}
    </Dialog.View>
  );
};
